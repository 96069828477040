import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getMembers.module.sass'
import Card from '../../../../components/Card'
import Icon from '../../../../components/Icon'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import MembersTableLayout from '../../../../components/AppSettingComponent/MemberComponent/MemberTableComponent'
import { acceptedUser } from '../mockData/acceptedUser'
// import { invitedUser } from '../mockData/invitedUser'
import Control from './Control'
import { getAcceptedMembersAction, getInvitedMembersAction } from '../../../../redux/action/AppSettings/Members/MembersAction'
import Loader from '../../../../components/Loaders/LoaderProgress'
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import EmptyState from '../../../../components/EmptyStates'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext'

const Tabs = ["Accepted", "Invited"];

const GetMembers = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()

  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(0)
  const [limit, setLimit] = useState(10)
  const [count, setCount] = useState()

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    useEffect(() => {
      getUserAccess()
    }, [])

  const getAllAppMembers = () => {
    let getMember = {
      organisationId : localStorage.getItem('orgId'),
      projectId : appid,
      ...(activeIndex === 1 && {status : 'invited'})
    }
    if(activeIndex == 0){
      dispatch(getAcceptedMembersAction(getMember))
    }else{
      dispatch(getInvitedMembersAction(getMember))
    }
  }

  useEffect(() =>{
    getAllAppMembers()
  }, [page, activeIndex])

  let acceptedMembers = useSelector((state) => {
    return state.members.acceptedMembers
  })

  let invitedMembers = useSelector((state) => {
    return state.members.invitedMembers
  })

  let isLoading = useSelector((state)=>{
    return state.members.isLoading
  })

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
        <ToastContainer />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title="Members" classTitle={cn("title-purple", styles.title)}
          head={
            ((activeIndex === 0 && acceptedMembers.length > 0)||(activeIndex === 1 && invitedMembers.length > 0)?
            <>
            {!userAccess &&
              <div className={styles.add}>
                <div className={styles.createButton}>
                  <Control memberAction='add' usedFor='app' type='small' members={acceptedMembers} />
                </div>
              </div>
            }
            </>
            : '')
          }
        >
          <div className={styles.wrapper}>
            <div className={styles.toggle}>
              {Tabs && Tabs.map((x, index) => (
                <button className={cn(styles.link, { [styles.active]: index === activeIndex,})} onClick={() => setActiveIndex(index)} key={index}>
                    <p>{x}</p>
                </button>
              ))}
            </div>
            {(activeIndex === 0 && acceptedMembers.length == 0)||(activeIndex === 1 && invitedMembers.length == 0) ?
            <>
              <div className={styles.wrapper}>
                <EmptyState memberAction='add' access={userAccess} usedFor='app' type="Members" />
              </div>
            </>
          : (activeIndex === 0 && acceptedMembers.length > 0)||(activeIndex === 1 && invitedMembers.length > 0) ?
          <>
          <div className={styles.wrapper}>
            <div className={styles.seprator}></div>
              <div className={styles.options}>
                <div className={styles.filters}>
                  <div className={styles.buttons}>
                    <Icon name='filter' size='20' className={styles.icon} />
                    <span className={styles.buttonText}>Filter</span>
                  </div>
                  <div className={styles.buttons}>
                    <ArchiveSvg className={styles.icon} />
                    <span className={styles.buttonText}>Show Archived</span>
                  </div>
                </div>
              <div className={styles.searchForm}>
                <Form className={styles.form} placeholder="Search Members" type="text" name="search" icon="search" />
              </div>
            </div>
            {activeIndex === 0 &&
              <>
                <MembersTableLayout to='accepted' items={acceptedMembers} access={userAccess} title="Last edited" />
              </>  
            }
            {activeIndex === 1 && 
              <>
                <MembersTableLayout to='invited' items={invitedMembers} access={userAccess} title="Last edited" />
              </>
            }
          </div>
          <div className={styles.pagination}>
            <Stack>
              <Pagination renderItem={(item) => (
                    <PaginationItem
                        {...item}
                        sx={{
                        '&.Mui-selected': {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                            color: 'rgb(255, 255, 255)',
                            fontWeight : 'bold',
                            border : '1px solid rgb(42, 133, 255)',
                            background : 'rgba(42, 133, 255)'
                        }
                        }}
                    />
                )} siblingCount={1} color="primary" />
              </Stack>
            </div>
          </>
          : ''}
          </div>
        </Card>        
    </>
  )

}

export default GetMembers