import logo from './logo.svg';
import './styles/app.sass'
import {BrowserRouter as Router, Routes, Route, useParams } from "react-router-dom";
import {getLoggedInUser} from './redux/action/authenticate/authenticationAction'
import PrivateRoute from './reusable/PrivateRoute';
import { Provider, useSelector } from 'react-redux';
import React, { useEffect, Suspense, useState, useContext } from 'react';
import { routes } from './routes/routes';

import Overview from './screens/Overview'
import Sidebar from './components/Sidebar';
import Test from './components/Organisation/Sidebar'
import Page from './components/Page';
import { store, persistor } from './redux/store/store';

import Currency from './screens/Builds/Economy/Currency/GetCurrency/currency'
import ViewCurrency from './screens/Builds/Economy/Currency/ViewCurrency';
import CreateCurrency from './screens/Builds/Economy/Currency/CreateCurrency';
import EditCurrency from './screens/Builds/Economy/Currency/EditCurrency';

import Items from './screens/Builds/Economy/Items/GetItems'
import ViewItem from './screens/Builds/Economy/Items/ViewItems'
import CreateItems from './screens/Builds/Economy/Items/CreateItems';
import EditItems from './screens/Builds/Economy/Items/EditItems'

import Bundles from './screens/Builds/Economy/Bundles/GetBundles'
import CreateBundle from './screens/Builds/Economy/Bundles/CreateBundles';
import EditBundle from './screens/Builds/Economy/Bundles/EditBundles'

import SignUp from './screens/Authentication/Register/Email';
import UserDetail from './screens/Authentication/Register/UserDetail'
import Login from './screens/Authentication/SignIn'
import EmailValidate from './screens/Authentication/SignUp/EmailValidation'

import Apps from './screens/Organization/Apps';
import PageLayout from './components/Organisation/Page';
import AppInformation from './screens/AppInformation';
import AppSettingsInfo from './screens/AppSettingsInfo';

import GetProgressionMarker from './screens/Builds/Progression/ProgressionMarker/GetProgressionMarker';
import ViewProgressionMarker from './screens/Builds/Progression/ProgressionMarker/ViewProgressionMarker';
import GetProgressionSystem from './screens/Builds/Progression/ProgressionSystem/GetProgressionSystem';
import ViewProgressionSystem from './screens/Builds/Progression/ProgressionSystem/ViewProgressionSystem';
import CreateProgressionMarker from './screens/Builds/Progression/ProgressionMarker/CreateProgressionMarker';
import CreateProgressionSystem from './screens/Builds/Progression/ProgressionSystem/CreateProgressionSystem'
import EditProgressionMarker from './screens/Builds/Progression/ProgressionMarker/EditProgressionMarker';
import { ForgotPassword } from './screens/Authentication/ForgotPassword';
import EditProgressionSystem from './screens/Builds/Progression/ProgressionSystem/EditPrgoressionSystem';
import Events from './screens/Builds/Events/GetEvent';
import CreateEvents from './screens/Builds/Events/CreateEvent';
import ViewCustomEvent from './screens/Builds/Events/ViewEvent/ViewCustomEvent'
import ViewDefaultEvent from './screens/Builds/Events/ViewEvent/ViewDefaultEvent'
import EditCustomeEvents from './screens/Builds/Events/EditEvent/EditCustomEvents'
import EditDefaultEvents from './screens/Builds/Events/EditEvent/EditDefaultEvents'
import Task from './screens/Engage/Achievement/Tasks/GetTask';
import CreateTask from './screens/Engage/Achievement/Tasks/CreateTask';
import ViewTask from './screens/Engage/Achievement/Tasks/ViewTask';
import EditTask from './screens/Engage/Achievement/Tasks/EditTask';
import StepSeries from './screens/Engage/Achievement/StepSeries/GetStepSeries';
import ViewStepSeries from './screens/Engage/Achievement/StepSeries/ViewStepSeries';
import CreateStepSeries from './screens/Engage/Achievement/StepSeries/CreateStepSeries';
import EditStepSeries from './screens/Engage/Achievement/StepSeries/EditStepSeries';
import GetMission from './screens/Engage/Achievement/Missions/GetMission';
import ViewMission from './screens/Engage/Achievement/Missions/ViewMission';
import CreateMission from './screens/Engage/Achievement/Missions/CreateMission';
import EditMission from './screens/Engage/Achievement/Missions/EditMission';
import GetPlayers from './screens/Engage/Players/GetPlayers';
import PlayerDetail from './screens/Engage/Players/PlayerDetails';

import Games from './screens/Builds/Games/GetGames'
import ViewGame from './screens/Builds/Games/ViewGames'
import CreateGame from './screens/Builds/Games/CreateGames';
import EditGame from './screens/Builds/Games/EditGames';

import Matches from './screens/Builds/Matches/GetMatches'
import ViewMatch from './screens/Builds/Matches/ViewMatches'
import CreateMatch from './screens/Builds/Matches/CreateMatches'
import EditMatch from './screens/Builds/Matches/EditMatches'
import CreateStore from './screens/Builds/Economy/Store/CreateStore';
import ViewStore from './screens/Builds/Economy/Store/ViewStore';
import Stores from './screens/Builds/Economy/Store/GetStores/index';
import EditStores from './screens/Builds/Economy/Store/EditStores';

import Leaderboard from './screens/Engage/Leaderboard/GetLeaderboard'
import ViewLeaderboard from './screens/Engage/Leaderboard/ViewLeaderboard'
import LeaderboardResult from './screens/Engage/Leaderboard/LeaderboardResult'
import CreateLeaderboard from './screens/Engage/Leaderboard/CreateLeaderboard'
import EditLeaderboard from './screens/Engage/Leaderboard/EditLeaderboard'
import GetCommunity from './screens/Community/GetCommunity';

import GetCompetitionTournament from './screens/Engage/Competitions/GetCompetition/GetCompetitionTournament';
import GetCompetitionInstantBattle from './screens/Engage/Competitions/GetCompetition/GetCompetitionInstantBattle';
import ViewCompetitionTournament from './screens/Engage/Competitions/ViewCompetition/ViewTournament';
import ViewCompetitionInstantBattle from './screens/Engage/Competitions/ViewCompetition/ViewInstantBattle';
import CreateCompetitionTournament from './screens/Engage/Competitions/CreateCompetition/Tournament';
import CreateCompetitionInstantBattle from './screens/Engage/Competitions/CreateCompetition/InstantBattle';
import EditCompetitionTournament from './screens/Engage/Competitions/EditCompetition/Tournament';
import EditCompetitionInstantBattle from './screens/Engage/Competitions/EditCompetition/InstantBattle';
import GetCompetitionSchedule from './screens/Engage/CompetitionSchedule/GetCompetitionSchedule'
import CreateCompetitionSchedule from './screens/Engage/CompetitionSchedule/CreateCompetitionSchedule'
import ViewCompetitionSchedule from './screens/Engage/CompetitionSchedule/ViewCompetitionSchedule'
import EditCompetitionSchedule from './screens/Engage/CompetitionSchedule/EditCompetitionSchedule'
import InstantBattleResult from './screens/Engage/Competitions/CompetitionResult/IBResult'
import TournamentResult from './screens/Engage/Competitions/CompetitionResult/TournamentResult'

import Members from './screens/AppSettings/Members/GetMembers';
import MemberInvite from './screens/Authentication/MemberInviteSetPassword'
import GetAPI from './screens/AppSettings/API/GetAPI'

import OrgMembers from './screens/Organization/Settings/Members'
import OrgApps from './screens/Organization/Settings/Apps'
import OrgProfile from './screens/Organization/Settings/Profile/Organisation'
import Media from './screens/AppSettings/Media';
import GetDocuments from './screens/AppSettings/Documents/GetDocument';
import CreateDocuments from './screens/AppSettings/Documents/CreateDocument';
import MediaFolders from './screens/AppSettings/MediaFolders';
import UserProfile from './screens/Organization/Settings/Profile/User';
import ResetPassword from './screens/Authentication/ResetPassword';
import GlobalStates from './components/Context/GlobalStates/GlobalStates';
import Modal from './components/Modals/ModalComponent';
import IntroductionModal from './components/Modals/IntroductionModal';
import GlobalStateContext from './components/Context/GlobalStates/GlobalStateContext';
import Calendar from './screens/Engage/LiveOps/Calendar';
import ViewBundle from './screens/Builds/Economy/Bundles/ViewBundle';
import Terminal from './screens/Terminal'
import EditDocument from './screens/AppSettings/Documents/EditDocument';

function App() {

  const {appid} = useParams()

  const token = localStorage.getItem('token')
  const user = JSON.parse(localStorage.getItem('user'))
  
  if(token){
    store.dispatch(getLoggedInUser(user))
  }

  const [showLoader, setShowLoader] = useState(false)
  const [loaderType, setLoaderType] = useState(null)
  const globalStates = useContext(GlobalStateContext)

  
  return (
    <>
      <GlobalStates>
        <div className='App'>
          <Router>
            <Routes>
              <Route path='/'>
                  <Route path={routes.AppOverview.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'><Overview /></Page></PrivateRoute>} />
                  <Route path={routes.AppSettingsInfo.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings'><AppSettingsInfo /></Page></PrivateRoute>} />
                  <Route path={routes.AppInfo.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'><AppInformation /></Page></PrivateRoute>} />
                  <Route path={routes.Games.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Games /></Page></PrivateRoute>} />
                  <Route path={routes.ViewGame.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Game'><ViewGame /></Page></PrivateRoute>} />
                  <Route path={routes.CreateGame.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Game'><CreateGame /></Page></PrivateRoute>} />
                  <Route path={routes.EditGames.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Game'><EditGame /></Page></PrivateRoute>} />
                  <Route path={routes.Matches.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Matches /></Page></PrivateRoute>} />
                  <Route path={routes.ViewMatch.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Match'><ViewMatch /></Page></PrivateRoute>} />
                  <Route path={routes.CreateMatch.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Match'><CreateMatch /></Page></PrivateRoute>} />
                  <Route path={routes.EditMatch.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Match'><EditMatch /></Page></PrivateRoute>} />
                  <Route path={routes.Currency.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Currency /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCurrency.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Currency"><ViewCurrency /></Page></PrivateRoute>} />                  
                  <Route path={routes.CreateCurrency.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Currency"><CreateCurrency /></Page></PrivateRoute>} />
                  <Route path={routes.EditCurrency.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Currency"><EditCurrency /></Page></PrivateRoute>} />
                  <Route path={routes.Items.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Items /></Page></PrivateRoute>} />
                  <Route path={routes.ViewItem.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Item"><ViewItem /></Page></PrivateRoute>} /> 
                  <Route path={routes.CreateItems.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Item"><CreateItems /></Page></PrivateRoute>} />
                  <Route path={routes.EditItems.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Item"><EditItems /></Page></PrivateRoute>} />
                  <Route path={routes.Bundles.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Bundles /></Page></PrivateRoute>} />
                  <Route path={routes.ViewBundle.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Bundle"><ViewBundle /></Page></PrivateRoute>} />    
                  <Route path={routes.CreateBundles.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Bundle"><CreateBundle /></Page></PrivateRoute>} />
                  <Route path={routes.EditBundles.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Bundle"><EditBundle /></Page></PrivateRoute>} />
                  <Route path={routes.CreateStore.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Store"><CreateStore /></Page></PrivateRoute>} />
                  <Route path={routes.Stores.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Stores /></Page></PrivateRoute>} />
                  <Route path={routes.ViewStore.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Stores"><ViewStore /></Page></PrivateRoute>} />
                  <Route path={routes.EditStores.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Stores"><EditStores /></Page></PrivateRoute>} />
                  <Route path={routes.GetProgressionMarker.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.ViewProgressionMarker.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Progression Marker"><ViewProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.CreateProgressionMarker.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Progression Marker"><CreateProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.EditProgressionMarker.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Progression Marker"><EditProgressionMarker /></Page></PrivateRoute>} />
                  <Route path={routes.GetProgressionSystem.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.ViewProgressionSystem.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Progression System"><ViewProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.CreateProgressionSystem.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Progression System"><CreateProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.EditProgressionSystem.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Progression System"><EditProgressionSystem /></Page></PrivateRoute>} />
                  <Route path={routes.Events.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Events /></Page></PrivateRoute>} />
                  <Route path={routes.CreateEvents.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Event"><CreateEvents /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCustomEvents.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Event"><ViewCustomEvent /></Page></PrivateRoute>} />
                  <Route path={routes.ViewDefaultEvents.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Event"><ViewDefaultEvent /></Page></PrivateRoute>} />
                  <Route path={routes.EditCustomEvents.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Event"><EditCustomeEvents /></Page></PrivateRoute>} />
                  <Route path={routes.EditDefaultEvents.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Event"><EditDefaultEvents /></Page></PrivateRoute>} />
                  <Route path={routes.Task.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Task /></Page></PrivateRoute>} />
                  <Route path={routes.CreateTask.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Task"><CreateTask /></Page></PrivateRoute>} />
                  <Route path={routes.ViewTask.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Task"><ViewTask /></Page></PrivateRoute>} />
                  <Route path={routes.EditTask.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Task"><EditTask /></Page></PrivateRoute>} />
                  <Route path={routes.StepSeries.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><StepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.CreateStepSeries.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Step Series"><CreateStepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.ViewStepSeries.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Step Series"><ViewStepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.EditStepSeries.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Step Series"><EditStepSeries /></Page></PrivateRoute>} />
                  <Route path={routes.Missions.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetMission /></Page></PrivateRoute>} />
                  <Route path={routes.ViewMission.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Mission"><ViewMission /></Page></PrivateRoute>} />
                  <Route path={routes.CreateMission.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Create Mission"><CreateMission /></Page></PrivateRoute>} />
                  <Route path={routes.EditMission.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Mission"><EditMission /></Page></PrivateRoute>} />
                  <Route path={routes.GetPlayers.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetPlayers /></Page></PrivateRoute>} />
                  <Route path={routes.PlayerDetails.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'><PlayerDetail /></Page></PrivateRoute>} />
                  <Route path={routes.Leaderboard.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Leaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.ViewLeaderboard.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Leaderboard'><ViewLeaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.CreateLeaderboard.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Leaderboard'><CreateLeaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.EditLeaderboard.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Leaderboard'><EditLeaderboard /></Page></PrivateRoute>} />
                  <Route path={routes.LeaderboardResult.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><LeaderboardResult /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionResult.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><TournamentResult /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionInstantBattleResult.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><InstantBattleResult /></Page></PrivateRoute>} />
                  <Route path={routes.Calendar.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><Calendar /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionSchedule.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCompetitionSchedule.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="View Competition"><ViewCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.CreateCompetitionSchedule.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Schedule Competition"><CreateCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.EditCompetitionSchedule.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title="Edit Schedule Competition"><EditCompetitionSchedule /></Page></PrivateRoute>} />
                  <Route path={routes.Community.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'><GetCommunity /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionTournament.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.CompetitionInstantBattle.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' background={true}><GetCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCompetitionTournament.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Tournament'><ViewCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.ViewCompetitionInstantBattle.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='View Instant Battle'><ViewCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.CreateCompetitionTournament.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Tournament'><CreateCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.CreateCompetitionInstantBattle.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Create Instant Battle'><CreateCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.EditCompetitionTournament.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Tournament'><EditCompetitionTournament /></Page></PrivateRoute>} />
                  <Route path={routes.EditCompetitionInstantBattle.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App' title='Edit Instant Battle'><EditCompetitionInstantBattle /></Page></PrivateRoute>} />
                  <Route path={routes.AppMembers.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' background={true}><Members /></Page></PrivateRoute>} />
                  <Route path={routes.API.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' background={true}><GetAPI /></Page></PrivateRoute>} />
                  <Route path={routes.Media.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' background={true}><Media /></Page></PrivateRoute>} />
                  <Route path={routes.MediaFolders.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' background={true}><MediaFolders /></Page></PrivateRoute>} />
                  <Route path={routes.Documents.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' background={true}><GetDocuments /></Page></PrivateRoute>} />
                  <Route path={routes.CreateDocuments.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' title='Create Document'><CreateDocuments /></Page></PrivateRoute>} />
                  <Route path={routes.EditDocuments.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='AppSettings' title='Edit Document'><EditDocument /></Page></PrivateRoute>} />
                  <Route path={routes.Terminal.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='App'><Terminal /></Page></PrivateRoute>} />
                  {/* ORGANISATION PATH */}
                  <Route path={routes.Dashboard.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization'><Apps /></Page></PrivateRoute>} />
                  <Route path={routes.Apps.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}  type='Organization' background={true}><OrgApps /></Page></PrivateRoute>} />
                  <Route path={routes.OrganisationMembers.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization' background={true}><OrgMembers /></Page></PrivateRoute>} />
                  <Route path={routes.OrganisationProfile.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization'><OrgProfile /></Page></PrivateRoute>} />
                  <Route path={routes.UserProfile.path} element={<PrivateRoute><Page showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} type='Organization'><UserProfile /></Page></PrivateRoute>} />
                  {/* LOGIN AND SIGN UP */}
                  <Route path={routes.EmailValidate.path} element={<EmailValidate />} />
                  <Route index element={<Login showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}  />} />
                  <Route path={routes.SignUp.path} element={<SignUp />} />
                  <Route path={`/user-detail`} element={<UserDetail showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}/>} />
                  <Route path={routes.ForgotPassword.path} element={<ForgotPassword showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType} />} />
                  <Route path={routes.ResetPassword.path} element={<ResetPassword showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}/>} />
                  <Route path={routes.MemberInviteSetPassword.path} element={<MemberInvite showLoader={showLoader} setShowLoader={setShowLoader} loaderType={loaderType} setLoaderType={setLoaderType}/>} />
              </Route>
            </Routes>
          </Router>
        </div>
      </GlobalStates>
    </>
  );
}
export default App;