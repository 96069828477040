import React, { useState, useEffect, useContext } from "react";
import styles from "./Sidebar.module.sass";
import { Link, NavLink,useNavigate, useLocation, useParams } from "react-router-dom";
import cn from "classnames";
import Icon from "../Icon";
import Theme from "../Theme";
// import Help from "./Help";
import Dropdown from "./Dropdown";
import Image from "../Image";
import { ReactComponent as  DirtcubeSvg} from "../../media/images/dirtcube_profile.svg";
import { ReactComponent as  SpectreProfileSvg} from "../../media/images/Spectre.svg";
import { ReactComponent as  AddIconSvg} from "../../media/images/SidebarAdd.svg";
import { ReactComponent as  ActiveApps} from "../../media/images/icons/active.svg";
import { ReactComponent as  Divider} from "../../media/images/icons/Divider.svg";
import { ReactComponent as  GameIcon} from "../../media/images/icons/AppInformation.svg";
import { ReactComponent as  ServerIcon} from "../../media/images/icons/server_icon.svg";
import { getallAppAction } from "../../redux/action/apps/appsAction";
import EnvironmentSwitchTooltip from '../../components/CustomTooltips/EnvironmentSwitchTooltip'
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { BASE_URL, GET_APPS } from "../../resources/APIEndpoints";
import { element } from "prop-types";
import { AppMenu } from "../AppMenu";
import AppPlaceHolder from '../../media/images/placeholders/App.png'
import AddAppsModal from "../../components/Organisation/Apps/Modals/AddAppModal";
import Modal from "../../components/Modals/ModalComponent";
import ConfirmNavigationModal from "../../components/ConfirmNavigationModal";
import { getAllGamesAction } from "../../redux/action/builds/games/gamesAction";

import { ReactComponent as  Home} from "../../media/images/icons/home.svg";
import { ReactComponent as  Build} from "../../media/images/icons/build.svg";
import { ReactComponent as  Engage} from "../../media/images/icons/engage.svg";
import { ReactComponent as  Community} from "../../media/images/icons/community_growth.svg";
import { ReactComponent as  Dashboard} from "../../media/images/icons/dashboard.svg";
import { ReactComponent as  Apps} from "../../media/images/icons/apps.svg";
import { ReactComponent as  ActivityFeed} from "../../media/images/icons/activity_feed.svg";
import { ReactComponent as  Settings} from "../../media/images/icons/settings.svg";
import { ReactComponent as  AccessManager} from "../../media/images/icons/access_manager.svg";
import { ReactComponent as  TerminalIcon} from "../../media/images/icons/terminal.svg";
import { ReactComponent as  MediaIcon} from "../../media/images/icons/image_icon.svg";
import { ReactComponent as  MemberIcon} from "../../media/images/icons/members_icon.svg";
import { ReactComponent as  ApiIcon} from "../../media/images/icons/api_icon.svg";
import { ReactComponent as  Info} from "../../media/images/icons/info.svg";
import { getOrgProfileAction } from "../../redux/action/organisation/ProfileAction/profileAction";
import ProfilePlaceHolder from '../../media/images/placeholders/Profile.png'
import GlobalStateContext from "../Context/GlobalStates/GlobalStateContext";

const Sidebar = ({ className, onClose, type, isChanged,setIsChanged, showLoader, setShowLoader, appName, setAppName, setLoaderType}) => {

    const { pathname } = useLocation();
    const dispatch = useDispatch()
    const {appid} = useParams()
    const Navigate = useNavigate();
    const [visibleHelp, setVisibleHelp] = useState(false);
    const [visible, setVisible] = useState(false);
    const [GamesMenu, setGamesMenu] = useState([])
    const [visibleModal, setVisibleModal] = useState(false);
    const [visibleMod, setVisibleMod] = useState(false);
    const [lnk, setLnk] = useState()
    const [appIcon,setAppIcon]=useState(null)

    const globalStates = useContext(GlobalStateContext)    

    const navigation = [
        {
            title: "Overview",
            icon: <Home />,
            url: `/app-overview/${appid}`,
        },
        {
            title: "Build",
            slug: "products",
            icon: <Build />,
            add: false,
            dropdown: [
                {
                    title: "Games",
                    url: `/games/${appid}`,
                },
                {
                    title: "Matches",
                    url: `/matches/${appid}`,
                },
                {
                    title: "Economy",
                    url: `/currency/${appid}`,
                },
                {
                    title: "Progression",
                    url: `/progression-marker/${appid}`,
                },
                {
                    title: "Events",
                    url: `/events/${appid}`,
                },
            ],
        },
        {
            title: "Engage",
            icon: <Engage />,
            add: false,
            dropdown: [
                {
                    title: "Players",
                    url: `/players/${appid}`,
                },
                {
                    title: "Achievements",
                    url: `/task/${appid}`,
                },
                {
                    title: "Competitions",
                    url: `/competition-tournament/${appid}`,
                },
                {
                    title: "Leaderboards",
                    url: `/leaderboard/${appid}`,
                }
            ],
        },
        // {
        //     title: "Terminal",
        //     icon: <TerminalIcon />,
        //     url: `/terminal/${appid}`,
        // },
        {
            title: "Terminal",
            icon: <TerminalIcon />,
            url: `/terminal/${appid}`,
        },
        {
            title: "Environment Settings",
            icon: <Settings />,
            url: `/env-information/${appid}`,
        }
    ];

    const organizationMenu = [
        {
            title: "Dashboard",
            icon : <Dashboard />,
            active : true,
            url: "/dashboard"
        },
        {
            title: "Apps",
            icon : <Apps />,
            active : true,
            url: "/apps"
        },
        {
            title: "Settings",
            icon : <Settings />,
            active : true,
            url: "/settings-organization-profile"
        },
        {
            title: "Access Manager",
            icon : <AccessManager />,
            active : true,
            url: "/access-manager"
        }
    ];


    const appSettings = [
        {
            title: "App Info",
            icon : <Info style={{fill:"#ffffff"}}/>,
            active : true,
            url: `/app-information/${appid}`
        },
        {
            title: "Media",
            icon : <MediaIcon />,
            active : true,
            url: `/app-media/${appid}`,
        },
        {
            title: "Documents",
            icon : <MediaIcon />,
            active : true,
            url: `/app-documents/${appid}`,
        },
        {
            title: "API",
            icon : <ApiIcon />,
            active : true,
            url : `/app-api/${appid}`
        },
        {
            title: "Members",
            icon : <MemberIcon />,
            active : true,
            url : `/app-members/${appid}`
        }
    ];



    const getAllApps = async() => {
        let getAllApps = {offset : 0, limit : 50}
        await dispatch(getallAppAction(getAllApps, setShowLoader))
    }

    let apps = useSelector((state)=>{
        return state.apps.app
    })

    useEffect(()=>{
        configureApps()
    }, [apps])

    const configureApps = () => {
        if(apps && apps.length > 0){
            setGamesMenu(apps && apps.map((element) => {return({id : element.id, appName : element.name , appIconUrl : (element.iconUrl != null ? element.iconUrl : AppPlaceHolder), url : `/app-overview/${element.id}`})}))
        }
    }

    const getAppName = (x) => {
        setAppName(x.appName)
        localStorage.setItem('App', x.appName)
        localStorage.setItem('AppIcon', x.appIconUrl)
    }
    const getAppIcon = () => {
        let appIcon=localStorage.getItem('AppIcon')
        setAppIcon(appIcon)
    }

    const refreshPage = () => {
        localStorage.setItem('environment', "Development")
        loaderConfigure(4)
    }

    const [isAppMenu, setIsAppMenu] = useState(false)
    const [appNameHolder, setAppNameHolder] = useState('')
    const onNavigateApps = (x) => {
        if (isChanged==true) {
            setIsAppMenu(true)
            setLnk(x.url)
            setAppNameHolder(x)
            setVisibleMod(true);
        } else {
            setIsAppMenu(false)
            getAppName(x)
            Navigate(x.url)
            refreshPage()
        }
    }

    const onNavigateLinks = (x) => {
        if (isChanged==true) {
            setLnk(x)
            setVisibleMod(true);
        } else {
            Navigate(x)
        }
    }

    const onCls = () => {
        setVisibleMod(false)
        setLnk()
        setAppNameHolder('')
    }

    const onProceed = () => {
        setVisibleMod(false)
        Navigate(lnk)
        setIsChanged(false)
        if(isAppMenu){
            getAppName(appNameHolder)
            setIsAppMenu(false)
            refreshPage()
        }
    }
    
    useEffect(()=>{
        getAllApps()
    }, [])

    const [organisation, setOrganisation] = useState({name : '', iconUrl : ''})

    const getLocalStorageData = () => {
        const env = localStorage.getItem('environment')
        setEnvironment(env == 'Staging' ? 'Quality Assurance' : env)
    }
    
    const loaderConfigure = (loaderType) => {
        setLoaderType(loaderType)
        setShowLoader(true)
        setTimeout(() => {
            setShowLoader(false)
        }, 4000)
    }

    const [environmentOption, setEnvironmentOption] = useState([{id : 1, name : 'Production', active: (localStorage.getItem('environment') =='Production' ? true :false) },{id : 2, name : 'Quality Assurance', active: (localStorage.getItem('environment') =='Staging' ? true :false)}, {id : 3, name : 'Development', active: (localStorage.getItem('environment') =='Development' ? true :false)}])
    const [environment, setEnvironment] = useState()

    const resetEnvironment = () => {
        localStorage.setItem('environment', 'Development')
        loaderConfigure(5)
        onClose()
    }
    
    const getOrgProfile = () => {
        dispatch(getOrgProfileAction({}))
    }

    let localAppIcon=localStorage.getItem('AppIcon')

    useEffect(()=>{
        getLocalStorageData()
        getOrgProfile()
    }, [])

    useEffect(()=>{
        getAppIcon()
    },[localAppIcon])

    let OrgProfile = useSelector((state) => {
        return state.organisation.orgProfile
    })

    const configOrganisation = () => {
        if(OrgProfile){
            setOrganisation(org => ({...org, name : OrgProfile.name, iconUrl : OrgProfile.defaultLogoUrl}))
        }
    }
    

    useEffect(()=>{
        configOrganisation()
    }, [OrgProfile])

    return(
        <>
            <div className={cn(styles.sidebar, className, {[styles.active]: visible})}>
                <div className={styles.mainSidebar}>
                    <div className={styles.mainSidebarContainer}>
                        <div className={styles.appSideBar}>
                            <div className={styles.appSideBarContainer}>
                                <div className={styles.orgProfiles}>
                                    <div className={cn(styles.activeSvg, {[styles.active]: (pathname == '/dashboard' || pathname == '/apps' || pathname == '/settings-organisation-profile' || pathname == '/settings-member-profile' || pathname == '/access-manager')})} >
                                        <ActiveApps/>
                                    </div>
                                    <div className={styles.orgLogo} onClick={resetEnvironment}>
                                        <Link className={styles.logo} to="/dashboard" onClick={onClose}>
                                            <SpectreProfileSvg className={styles.pic}/>
                                        </Link>
                                    </div>
                                </div>

                                <div className={styles.divider}>
                                    <Divider />
                                </div>

                                <div className={styles.orgApps}>
                                    {GamesMenu && GamesMenu.map((x, index) =>
                                        (
                                            <>
                                                <div className={styles.appsList}>
                                                    <div className={cn(styles.activeApps, {[styles.active]: x.url.includes(appid)})}>
                                                        <ActiveApps />
                                                    </div>
                                                    <div className={styles.appIcon} onClick={()=>onNavigateApps(x)}>
                                                        <div key={index} state={{orgId : x.id}}>
                                                            <img className={styles.appIcons} src={x.appIconUrl} alt={`${x.appName}`} title={`${x.appName}`}/>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        )
                                    )}
                                </div>
                                {globalStates?.user?.type === 'admin' &&
                                    <div className={styles.addApps}>
                                        <div className={styles.appAddIcon}>
                                            <Link onClick={()=>{setVisibleModal(true)}} className={styles.button}>
                                                <AddIconSvg className={styles.appAddIcon}/>
                                            </Link>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className={styles.navSideBar}>
                            <div className={styles.navBarContainer}>
                                <button className={styles.close} onClick={onClose}>
                                    <Icon name="close" size="24" />
                                </button>
                                <div className={styles.orgTitle}>
                                    {type == 'App' &&
                                        <>
                                            <EnvironmentSwitchTooltip type='detail' setLoaderType={setLoaderType} showLoader={showLoader} setShowLoader={setShowLoader} appName={appName} data={environmentOption} environment={environment}>
                                                <div className={styles.environment}>
                                                    <ServerIcon className={environment == 'Production' ? styles.productionLogo : environment == 'Quality Assurance' ? styles.stagingLogo : styles.developLogo} />
                                                </div>
                                                <div className={styles.appName}>
                                                    <p className={styles.logoText}>{appName}</p>
                                                    <p className={styles.env}>{environment}</p>
                                                </div>
                                                <div className={styles.downArrow}>
                                                    <Icon name='arrow-down' size='20' />
                                                </div>
                                            </EnvironmentSwitchTooltip>
                                        </>
                                    }
                                    {type == 'AppSettings' &&
                                        <div style={{
                                            maxWidth: "200px",
                                            width: "100%",
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "left",
                                            gap: "7px",
                                        }}>
                                                <div>
                                                {(appIcon!==null&&appIcon!==undefined&&appIcon!='null'&&appIcon!=="")?<img className={styles.appLogo} src={appIcon} alt={"appIcon"}/>:<img className={styles.appLogo} src={AppPlaceHolder} alt={"appIcon"}/>}
                                                </div>
                                                <div className={styles.appName}>
                                                    <p className={styles.logoText}>{appName}</p>
                                                    <p className={styles.env}>{"Settings"}</p>
                                                </div>
                                                {/* {(window.location.pathname.includes(`/${appid}`)&&(window.location.pathname.includes('/app-information/')|| window.location.pathname.includes('/app-media/')|| window.location.pathname.includes('/app-members/')|| window.location.pathname.includes('/app-api/')))&&(
                                                    <div className={styles.downArrow}>
                                                        <button className={styles.headHelp} style={{marginRight:"24px"}} onClick={()=>Navigate(`/app-overview/${appid}`)}>
                                                            <Icon name='arrow-left' size='18' />
                                                        </button>
                                                    </div>
                                                )} */}
                                        </div>
                                    }
                                    {type == 'Organization' &&
                                        <>
                                            <div className={styles.organisation}>
                                                <div className={styles.orgIcon}>
                                                    <img src={organisation.iconUrl != null ? organisation.iconUrl : ProfilePlaceHolder} alt="orgLogo" />
                                                </div>
                                                <div className={styles.orgName}>
                                                    <h5 className={styles.orgText}>{organisation.name}</h5>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>

                                {type == 'App' ?
                                    <div className={styles.menu}>
                                        {navigation && navigation.map((x, index) =>
                                            x.url ? (
                                                <div className={cn(styles.item, {[styles.active]: x.url.includes(pathname.slice(0,16))})}
                                                onClick={()=>onNavigateLinks(x.url)} key={index}>
                                                    {/* {x.title == 'App Information' ?
                                                        <>
                                                            <GameIcon className={styles.custom}/>{x.title}
                                                        </>
                                                    : <><Icon name={x.icon} size="24" />{x.title}</>
                                                    } */}
                                                    {x.icon}{x.title}
                                                </div>
                                            ) : (
                                                <Dropdown onProceed={onProceed} onCls={onCls} NavTo={onNavigateLinks} className={styles.dropdown} visibleSidebar={visible}
                                                    setValue={setVisible} key={index} item={x} onClose={onClose} />
                                            )
                                        )}
                                    </div>
                                : ''}
                                {type == 'Organization' ?
                                    <div className={styles.menu}>
                                    {organizationMenu.map((x, index) =>
                                        x.url ? (
                                            <div className={cn(styles.item, {[styles.active]: x.url.includes(pathname.slice(0,16))})}
                                            onClick={x.active ? ()=>onNavigateLinks(x.url) : null} key={index} >
                                                {/* <Icon name={x.icon} size="24" /> */}{x.icon}{x.title}
                                            </div>
                                        ) : (
                                            <Dropdown onProceed={onProceed} onCls={onCls} NavTo={x.active ? onNavigateLinks : null} className={styles.dropdown} visibleSidebar={visible}
                                                setValue={setVisible} key={index} item={x} onClose={onClose}/>
                                        )
                                    )}
                                    </div>
                                : ''}

                                {type == 'AppSettings' ?
                                    <div className={styles.menu}>
                                    {appSettings.map((x, index) =>
                                        x.url ? (
                                            <div className={cn(styles.item, {[styles.active]: x.url.includes(pathname.slice(0,16))})}
                                            onClick={x.active ? ()=>onNavigateLinks(x.url) : null} key={index} >
                                                {/* <Icon name={x.icon} size="24" /> */}{x.icon}{x.title}
                                            </div>
                                        ) : (
                                            <Dropdown onProceed={onProceed} onCls={onCls} NavTo={x.active ? onNavigateLinks : null} className={styles.dropdown} visibleSidebar={visible}
                                                setValue={setVisible} key={index} item={x} onClose={onClose}/>
                                        )
                                    )}
                                    </div>
                                : ''}

                                <button className={styles.toggle} onClick={() => setVisible(!visible)}>
                                    <Icon name="arrow-right" size="24" />
                                    <Icon name="close" size="24" />
                                </button>

                                {/* <div className={styles.foot}>
                                    <Theme className={styles.theme} visibleSidebar={visible} />
                                </div> */}

                            </div>
                            {type == 'App' &&
                                    <div className={styles.organization}>
                                        <div className={styles.organizationLogo}>
                                            {/* <DirtcubeSvg /> */}
                                            <img src={organisation.iconUrl != null ? organisation.iconUrl : ProfilePlaceHolder} alt="orgLogo" />
                                        </div>
                                        <div className={styles.organizationName}>
                                            <h5 className={styles.orgText}>
                                                {organisation.name}
                                            </h5>
                                        </div>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={visibleModal} onClose={() => setVisibleModal(false)}>
                <AddAppsModal setLoaderType={setLoaderType} setApp={setAppName} setShowLoader={setShowLoader} onClose={() => setVisibleModal(false)}/>
            </Modal>
            <Modal visible={visibleMod} onClose={() => onCls()}>
                <ConfirmNavigationModal onProceed={()=>onProceed()} onClose={() => onCls()}/>
            </Modal>
        </>
    )
};

export default Sidebar;
