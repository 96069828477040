import { GET_APPS, GET_APP_OVERVIEW, ADD_APPS, EDIT_APPS, FILTER_APPS, GET_APP_LIST, GET_APP_DETAILS, GET_APP_LIST_SUCCESS, GET_APP_LIST_ERROR, FILTER_APPS_SUCCESS, FILTER_APPS_ERROR, GET_APP_OVERVIEW_SUCCESS, GET_APP_OVERVIEW_ERROR, GET_APP_DETAILS_SUCCESS, GET_APP_DETAILS_ERROR } from "../../actionTypes/actionTypes";
import AppServices from '../../../services/appServices/appServices'
import Toast from '../../../components/Toast'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const getallAppAction = (body, navigation) => {
    return async (dispatch) => {
        await AppServices.getAllApps(body)
        .then(res=>{
            dispatch(getAllApps(res.data.data.projectDetails, res.data.data.totalCount))
        })
    }
}

export const getAppDetailsAction = (body) => {
    return async (dispatch) => {
        dispatch(getAppDetails(null, GET_APP_DETAILS))
        await AppServices.getAllApps(body)
        .then(res => {
            dispatch(getAppDetails(res.data.data.projectDetails, GET_APP_DETAILS_SUCCESS))
        }).catch(err => {
            dispatch(getAppDetails(err.response, GET_APP_DETAILS_ERROR))
        })
    }
}

export const getListOfAppsAction = (body, configureSkeleton) => {
    return async (dispatch) => {
        dispatch(getAppList(null, null, GET_APP_LIST))
        await AppServices.getAllApps(body)
        .then(res => {
            if(configureSkeleton){
                configureSkeleton()
            }
            dispatch(getAppList(res.data.data.projectDetails, res.data.data.totalCount, GET_APP_LIST_SUCCESS))
        }).catch(err => {
            dispatch(getAppList(err.response, null, GET_APP_LIST_ERROR))
        })
    }
}

export const filterAppsAction = (body, configureSkeleton) => {
    return async (dispatch) => {
        await AppServices.getAllApps(body)
        .then(res=>{
            if(configureSkeleton){
                configureSkeleton()
            }
            dispatch(filterApps(res.data.data.projectDetails, ((body?.search && body?.search !== '') ? res.data.data.searchCount : res.data.data.totalCount) , FILTER_APPS_SUCCESS))
        }).catch(err => {
            dispatch(filterApps(err.response, null, FILTER_APPS_ERROR))
        })
    }
}

export const getAppOverviewAction = (body) => {
    return async (dispatch) => {
        dispatch(getAppOverview(null, GET_APP_OVERVIEW))
        await AppServices.getAppOverview(body)
        .then(res=>{
            dispatch(getAppOverview(res.data.data, GET_APP_OVERVIEW_SUCCESS))
        }).catch(err => {
            dispatch(getAppOverview(err.response, GET_APP_OVERVIEW_ERROR))
        })
    }
}

export const createAppAction = (body, navigate, setApp, setShowLoader, setLoaderType) => {
    return async (dispatch) => {
        await AppServices.createApps(body)
        .then(res=>{
            setApp(body.name)
            localStorage.setItem('AppIcon', body.iconUrl)
            localStorage.setItem('App', body.name)
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setLoaderType(3)
            setShowLoader(true)
            setTimeout(() => {
                navigate(`/app-overview/${res.data.data.projectDetails.id}`)
                localStorage.setItem('environment', "Development")
                // window.location.reload()
                setTimeout(() => {
                    setShowLoader(false)
                }, 4000)
            }, 300)
            
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editAppAction = (body, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await AppServices.editApps(body)
        .then(res=>{
            localStorage.setItem('AppIcon', body.iconUrl)
            localStorage.setItem('App', body.name)
            toast.success(<Toast type='Success' messages='App Updated Sucessfully'/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
               window.location.reload()
            }, 1000)
        }).catch(error => {
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const addAPIKeyAction = (body, appid) => {
    return async (dispatch) => {
        await AppServices.addAPIKeys(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            dispatch(getAppDetailsAction({ids : [appid]}))
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getAllApps = (data, count) => {
    return {
        type : GET_APPS,
        payload : data,
        count : count
    }
}

export const getAppOverview = (data, type) => {
    return {
        type : type,
        payload : data
    }
}

export const createApps = (data) => {
    return {
        type : ADD_APPS,
        payload : data
    }
}

export const editApps = (data) => {
    return {
        type : EDIT_APPS,
        payload : data
    }
}

export const filterApps = (data, count, type) => {
    return {
        type : type,
        payload : data,
        count: count
    }
}

export const getAppList = (data, count, type) => {
    return {
        type : type,
        payload : data,
        count : count
    }
}

export const getAppDetails = (data, type) => {
    return {
        type : type,
        payload : data,
    }
}

export const addAPIKeys = (data, type) => {
    return {
        type : type,
        payload : data
    }
}