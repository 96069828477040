import React, { useEffect, useState } from "react";
import styles from "./table.module.sass";
import cn from "classnames";
import Checkbox from "../../../../Checkbox";
import Row from "./Row";
import SS from '../../../../../media/images/placeholders/Step_Series.png'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Tooltip from '../../../../CustomTooltips/ReusableTooltip'
import Control from "./Row/Control";
import { useParams } from "react-router-dom";
import {ReactComponent as Sort} from '../../../../../media/images/icons/filled.svg'
import {ReactComponent as TableSort} from '../../../../../media/images/icons/sort.svg'

const Table = ({modalFor, access, order, usedFor, to, items, currency, setCurrency, currencyCount, currencyPage, item, setItem, itemCount, itemPage, bundle, setBundle, bundleCount, bundlePage, PM, setPM, PMCount, PMPage, setValue, setStepSeries, as, type}) => {

  const {appid} = useParams()
  const [characters, updateCharacters] = useState(items);

  const desiredLength = (type === 'table' ? 8 : 100000);
  items = items.concat(Array(desiredLength - items.length).fill({})).slice(0, desiredLength)

  function handleOnDragEnd(result) {
    if (!result.destination) return;
    const itemsData = Array.from(characters);
    const [reorderedItem] = itemsData.splice(result.source.index, 1);
    itemsData.splice(result.destination.index, 0, reorderedItem);
    sessionStorage.setItem('step-series', JSON.stringify(itemsData))
    updateCharacters(itemsData);
  }

  const configureSteps = () => {
    updateCharacters(items)
  }

  useEffect(() => {
    if(type === 'list'){
      configureSteps()
    }
  }, [items])

  if(type == 'list' && usedFor == 'StepSeries'){
    return (
      <>
        <div>
          <div className={styles.heading}>
                <div className={styles.cardSort}><TableSort /></div>
                <div className={styles.cardImage}>Icon</div>
                <div className={styles.cardName}>Name</div>
                <div className={styles.cardId}>ID</div>
                <div className={styles.cardReward}>Rewards</div>
                <div className={styles.cardIcons}></div>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <div className={styles.container} {...provided.droppableProps} ref={provided.innerRef}>
                {characters && characters.map((x, index) => {
                  if(x.archive == false){
                    return(
                      <Draggable key={x.taskId} draggableId={x.taskId} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className={styles.cardContainer}>
                              <div className={styles.cardSort}>
                                <Sort />
                              </div>
                              <div className={styles.cardImage}>
                                <img src={x.iconUrl != null ? x.iconUrl : SS} alt="Item" width={90} height={90}/>
                              </div>
                              <div className={styles.cardName}>
                                <div className={styles.ssName}>{x.name}</div>
                                <div className={styles.ssNo}>Step {index+1}</div>
                              </div>
                              <div className={styles.cardId}>{x.taskId}</div>
                              <div className={styles.cardReward}>
                              {type == 'list' && as == 'add' &&
                                <>
                                    {
                                        x.rewardDetails && x.rewardDetails.slice(0,1).map((e,i)=>{
                                            return(
                                                <>
                                                    <div className={styles.prices}>
                                                        <span>{e.name}</span><span>({e.quantity})</span>
                                                        {(x.rewardDetails && x.rewardDetails.length > 1 && i === 0) ? 
                                                            <Tooltip type='Task' text='Rewards' data={x.rewardDetails}><span className={styles.count}>+ {x.rewardDetails.length - 1}</span></Tooltip>  
                                                        : ''}
                                                    </div>
                                                </>   
                                            ) 
                                        })
                                    }
                                  </>
                                }
                                {type == 'list' && as == 'edit' &&
                                    <>
                                        {
                                            x.rewardDetails && x.rewardDetails.slice(0,1).map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.prices}>
                                                            <span>{e.name}</span><span>({e.quantity})</span>
                                                            {(x.rewardDetails && x.rewardDetails.length > 1 && i === 0) ? 
                                                                <Tooltip type='Task' text='Rewards' data={x.rewardDetails}><span className={styles.count}>+ {x.rewardDetails.length - 1}</span></Tooltip>  
                                                            : ''}
                                                        </div>
                                                    </>   
                                                ) 
                                            })
                                        }
                                    </>
                                }
                              </div>
                              <div className={styles.cardIcons}>
                                {!access && <Control className={styles.control} modalFor={modalFor} to={to} as={as} appid={appid} id={x.id} type={type} item={x} index={index} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} />}
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  }
                })}
                {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div> 
      </>
    );
  }else if(type == 'list' && (usedFor == 'Mission' && order == false)){
    return (
      <div className={styles.wrapper}>
        <div className={styles.table}>
          {items && items.filter(obj => obj.archive == false).length > 0 &&
          <div className={styles.row}>
            <div className={styles.col}>Icon</div>
            <div className={styles.col}>Display Name</div>
            <div className={styles.col}>ID</div>
            <div className={styles.col}>Rewards</div>
            <div className={styles.col}></div>
          </div>
          }
          {items && items.map((x, index) => {
            if(x.archive == false){
              return(
                <Row access={access} modalFor={modalFor} to={to} as={as} type={type} item={x} key={index} index={index} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} />
              )
            }
          })} 
        </div>
      </div>
    )
  }else if(type == 'list' && (usedFor == 'Mission' && order == true)){
    return (
      <>
        <div>
          <div className={styles.heading}>
                <div className={styles.cardSort}><TableSort /></div>
                <div className={styles.cardImage}>Icon</div>
                <div className={styles.cardName}>Name</div>
                <div className={styles.cardId}>ID</div>
                <div className={styles.cardReward}>Rewards</div>
                <div className={styles.cardIcons}></div>
          </div>
          <DragDropContext onDragEnd={handleOnDragEnd}>
            <Droppable droppableId="characters">
              {(provided) => (
                <div className={styles.container} {...provided.droppableProps} ref={provided.innerRef}>
                {characters && characters.map((x, index) => {
                  if(x.archive == false){
                    return(
                      <Draggable key={x.taskId} draggableId={x.taskId} index={index}>
                        {(provided) => (
                          <div ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                            <div className={styles.cardContainer}>
                              <div className={styles.cardSort}>
                                <Sort />
                              </div>
                              <div className={styles.cardImage}>
                                <img src={x.iconUrl != null ? x.iconUrl : SS} alt="Item" width={90} height={90}/>
                              </div>
                              <div className={styles.cardName}>
                                <div className={styles.ssName}>{x.name}</div>
                                {/* <div className={styles.ssNo}>Step {index+1}</div> */}
                              </div>
                              <div className={styles.cardId}>{x.taskId}</div>
                              <div className={styles.cardReward}>
                              {type == 'list' && as == 'add' &&
                                <>
                                    {
                                        x.rewardDetails && x.rewardDetails.slice(0,1).map((e,i)=>{
                                            return(
                                                <>
                                                    <div className={styles.prices}>
                                                        <span>{e.name}</span><span>({e.quantity})</span>
                                                        {(x.rewardDetails && x.rewardDetails.length > 1 && i === 0) ? 
                                                            <Tooltip type='Task' text='Rewards' data={x.rewardDetails}><span className={styles.count}>+ {x.rewardDetails.length - 1}</span></Tooltip>  
                                                        : ''}
                                                    </div>
                                                </>   
                                            ) 
                                        })
                                    }
                                  </>
                                }
                                {type == 'list' && as == 'edit' &&
                                    <>
                                        {
                                            x.rewardDetails && x.rewardDetails.slice(0,1).map((e,i)=>{
                                                return(
                                                    <>
                                                        <div className={styles.prices}>
                                                            <span>{e.name}</span><span>({e.quantity})</span>
                                                            {(x.rewardDetails && x.rewardDetails.length > 1 && i === 0) ? 
                                                                <Tooltip type='Task' text='Rewards' data={x.rewardDetails}><span className={styles.count}>+ {x.rewardDetails.length - 1}</span></Tooltip>  
                                                            : ''}
                                                        </div>
                                                    </>   
                                                ) 
                                            })
                                        }
                                    </>
                                }
                              </div>
                              <div className={styles.cardIcons}>
                              {!access && <Control className={styles.control} modalFor={modalFor} to={to} as={as} appid={appid} id={x.id} type={type} item={x} index={index} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} />}
                              </div>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    )
                  }
                })}
                {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div> 
      </>
    )
  }
  else if(type == 'table' && (usedFor == 'Mission' || usedFor == 'StepSeries')){
    return (
      <div className={styles.wrapper}>
        <div className={styles.table}>
          {items && items.filter(obj => obj.archive == false).length > 0 &&
          <div className={styles.row}>
            <div className={styles.col}>Icon</div>
            <div className={styles.col}>Display Name</div>
            <div className={styles.col}>{usedFor == 'Mission' ? 'No of tasks' : 'No of steps'}</div>
            <div className={styles.col}>Schedule</div>
            <div className={styles.col}>Current Instance</div>
            <div className={styles.col}>Status</div>
            <div className={styles.col}></div>
          </div>
          }
          {items && items.map((x, index) => {
            // if(x.archive == false){
              return(
                <Row isLast={index>=items.length-3&&index>=2} access={access} usedFor={usedFor} to={to} as={as} type={type} item={x} key={index} index={index} currency={currency} setCurrency={setCurrency} currencyCount={currencyCount} currencyPage={currencyPage} items={item} setItem={setItem} itemCount={itemCount} itemPage={itemPage} bundle={bundle} setBundle={setBundle} bundleCount={bundleCount} bundlePage={bundlePage} PM={PM} setPM={setPM} PMCount={PMCount} PMPage={PMPage} setValue={setValue} setStepSeries={setStepSeries} />
              )
            // }
          })} 
        </div>
      </div>
    )
  }else{
    return(
      <></>
    )
  }
};

export default Table;