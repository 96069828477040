import React, {useContext, useEffect, useState} from 'react'
import { Link, useParams } from 'react-router-dom'
import cn from 'classnames'
import styles from './stores.module.sass'
import Form from '../../../../../components/Form'
import Card from '../../../../../components/Card'
import axios from "axios";
import {ReactComponent as Check} from '../../../../../media/images/icons/Check_Svg.svg'
import { BASE_URL, GET_STORES } from '../../../../../resources/APIEndpoints';
import Icon from '../../../../../components/Icon'
import addIcon from '../../../../../media/images/icons/light.png'
import { useDispatch, useSelector } from 'react-redux'
import StoreTableLayout from '../../../../../components/BuildComponents/EconomyComponent/StoreComponent/StoreTableLayout'
import StoreCardLayout from '../../../../../components/BuildComponents/EconomyComponent/StoreComponent/StoreCardLayout'
import { ReactComponent as EmptyCurrencyState } from "../../../../../media/images/icons/Currency_Empty_State.svg";
import Loader from "../../../../../components/Loaders/LoaderProgress";
import EmptyState from '../../../../../components/EmptyStates'
import { createStoreAction, editStoreAction, filterStoresAction, getAllStoresAction } from '../../../../../redux/action/builds/economy/stores/storeAction'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material'
import SkeletonTable from '../../../../../components/Skeleton/TableSkeleton'
import SkeletonEmptyState from '../../../../../components/Skeleton/EmptyStateSkeleton'
import Control from './Filters/Control'
import GlobalStateContext from '../../../../../components/Context/GlobalStates/GlobalStateContext'
import customAxios from '../../../../../utils/interceptor'
import ExportTooltip from '../../../../../components/CustomTooltips/ExportTooltip'
import ControlFileUpload from './Control'
import XLSX from 'xlsx';
import { getAllCurrencyAction } from '../../../../../redux/action/builds/economy/currency/currencyAction'
import { getAllProgressionSystemAction } from '../../../../../redux/action/builds/progression/progressionSystem/progressionSystemAction'
import { getAllBundlesAction } from '../../../../../redux/action/builds/economy/bundles/bundleAction'
import { getAllItemAction } from '../../../../../redux/action/builds/economy/items/itemAction'
import { ToastContainer } from 'react-toastify'
import { endStoreApiCall, startStoreApiCalls } from '../../../../../sagas/actions/stores/storeUploadAction'

const sorting = ["list", "grid"];

const GetStores = () => {
  const {appid} = useParams()
  const dispatch = useDispatch()
  const [activeIndex, setActiveIndex] = useState(0);
  const [search, setSearch] = useState(null);
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)
  // Archive
  const [showArchive, setShowArchive] = useState(false)
  // Filters
  const [location, setLocation] = useState([])
  const [platform, setPlatform] = useState([])
  const [locationOption, setLocationOption] = useState([])
  const filters = {location, setLocation, platform, setPlatform}
  // Sort
  const sortList = [{id: 1, name: 'Store Name',value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)

  const [userAccess, setUserAccess] = useState(false)
  const globalStates = useContext(GlobalStateContext)

  const getUserAccess = () => {
    if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
      let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
      if(appPermission?.length > 0){
        setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
      }
    }
  }
  
  useEffect(() => {
    getUserAccess()
  }, [])

  const getAllStores = async(search) => {
      let stores = {
        projectId : appid,
        offset: ((page-1)*limit),
        limit: limit,
        // Search
        ...(search && search !== null && search !== '' && {search : search}),
        // Archive
        showArchived: showArchive,
        // Filters
        storePlatforms : platform,
        storeLocations : location,
        sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
        sortField : selectedSort,
      }
      let keysToCheck = ['location', 'platform']
      let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
      if(!search && isFiltered){
        await dispatch(getAllStoresAction(stores, configureSkeleton))
      }else{
        await dispatch(filterStoresAction(stores, configureSkeleton))
      }
  }

  let stores = useSelector((state)=>{
    return state.stores.stores
  })

  let totalStore = useSelector((state)=>{
    return state.stores.totalCount
  })

  let isLoading = useSelector((state)=>{
    return state.stores.isLoading
  })

  let isFiltered = useSelector((state) => {
    return state.stores.isFiltered
  })

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  const getOptionalData = async() => {
    const token = localStorage.getItem('token')
    const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
    let res = await axios.get(`${BASE_URL}/master-data`, headers)
    setLocationOption(res.data.data.countryMaster)
  }

  useEffect(()=>{
    if(selectedSort !== null){
      getAllStores(search)
      getOptionalData()
    }
  }, [page, selectedSort, showArchive])

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getAllStores(null)
    }else{
      setSearch(search)
      getAllStores(search)
    }
  }

    // Import Data

    const [storeData, setStoreData] = useState([])
    const [storeFile, setStoreFile] = useState(null)
    const [pricingFile, setPricingFile] = useState(null)
    const [lockByFile, setLockByFile] = useState(null)

    // File Upload 

    const onCSVStore = (e) => {
        setStoreFile(e)
    }
    
    const onCSVPricing = (e) => {
        setPricingFile(e)
    }
    
    const onCSVFile = (e) => {
        setLockByFile(e)
    }

    // API Calls

    const [currencyList, setCurrencyList] = useState([])
    const [realWorldCurrency, setRealWorldCurrency] = useState([])
    const getRealWorldCurrency = async() => {
      let currencies = {
        projectId : appid,
        offset: 0,
        limit: 100
      }
      dispatch(getAllCurrencyAction(currencies))
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await axios.post(`${BASE_URL}/currencies/get-world-currency`, {}, headers)
      res.data.data && res.data.data.forEach((element) => {
        setRealWorldCurrency(prevState => [...prevState, {realWorldCurrencyId : element.id, name : element.code.concat(" (", `${element.symbol}`, ")"), type : 'IAP'}])
      })
    }
    
    let currency = useSelector((state) => {
      return state.currencies.currency
    })
    
    const configureCurrency = () => {
      if(currency && currency?.length > 0){
        setCurrencyList(currency)
      }
    }
    
    useEffect(() => {
      configureCurrency()
    }, [currency])
    
    useEffect(() => {
      getRealWorldCurrency()
      getAllProgressionSystem()
      getAllBundles()
      getAllItems()
    }, [])
    
    // API Call For Bundle
  
    const [BundleList, setBundleList] = useState([])
    const getAllBundles = () => {
      let bundles = {
        projectId : appid,
        offset : 0,
        limit : 1000
      }
      dispatch(getAllBundlesAction(bundles))
    }
    
    let Bundles = useSelector((state) => {
      return state.bundles.bundles
    })
    
    const configureBundle = () => {
      if(Bundles && Bundles?.length > 0){
        setBundleList(Bundles)
      }
    }
    
    useEffect(() => {
      configureBundle()
    }, [Bundles])
  
    // API Call For Items
  
    const [ItemList, setItemList] = useState([])
    const getAllItems = () => {
      let items = {
        projectId : appid,
        offset : 0,
        limit : 1000
      }
        dispatch(getAllItemAction(items))
    }
      
    let Items = useSelector((state) => {
      return state.item.items
    })
      
    const configureItems = () => {
      if(Items && Items?.length > 0){
        setItemList(Items)
      }
    }
      
    useEffect(() => {
      configureItems()
    }, [Items])
  
    // API Call For Progression System
    const [PSList, setPSList] = useState([])
    const getAllProgressionSystem = () => {
      let PS = {
        projectId : appid,
        offset : 0,
        limit : 1000
      }
      dispatch(getAllProgressionSystemAction(PS))
    }
    
    let PS = useSelector((state) => {
      return state.progressionSystem.progressionSystem
    })
    
    const configurePS = () => {
      if(PS && PS?.length > 0){
        setPSList(PS)
      }
    }
    
    useEffect(() => {
      configurePS()
    }, [PS])

    // Excel to JSON Data

    // 1. Item File

    const [storeFileErrors, setStoreFileErrors] = useState(null)
    const [pricingFileErrors, setPricingFileErrors] = useState(null)
    const [lockByFileError, setLockByFileError] = useState(null)

    const uploadFunctions = {onCSVItem: onCSVStore, onCSVPricing: onCSVPricing, onCSVFile: onCSVFile}
    const files = {itemFile: storeFile, setItemFile: setStoreFile, pricingFile: pricingFile, setPricingFile: setPricingFile, lockByFile: lockByFile, setLockByFile: setLockByFile}
    const fileErrors = {itemFileError: storeFileErrors, pricingFileErrors: pricingFileErrors, lockByFileError: lockByFileError, setItemFileErrors: setStoreFileErrors, setPricingFileErrors: setPricingFileErrors, setLockByFileError: setLockByFileError}

    // Validate Store File

    // 1. Validate Uploaded Files

    // 1(A) Check Headers

    const checkErrors = (header) => {
        let newErrors = []
        let isError = true;
        if (header[0] !== 'Store Icon') {
            newErrors.push(`Please change 1st Column name from ${header[0]} to Store Icon.`)
            isError = false
        }
        if (header[1] !== 'Display Name') {
            newErrors.push(`Please change 2nd Column name from ${header[1]} to Display Name.`)
            isError = false
        }
        if (header[2] !== 'Store ID') {
            newErrors.push(`Please change 3rd Column name from ${header[2]} to Store ID.`)
            isError = false
        }
        if (header[3] !== 'Store Description') {
            newErrors.push(`Please change 4th Column name from ${header[3]} to Store Description.`)
            isError = false
        }
        if (header[4] !== 'Store Platform') {
            newErrors.push(`Please change 5th Column name from ${header[4]} to Store Platform.`)
            isError = false
        }
        if(header[5] !== 'Store Location') {
            newErrors.push(`Please change 6th Column name from ${header[5]} to Store Location.`)
            isError = false
        }
        if(header[6] !== 'Category Icon') {
            newErrors.push(`Please change 7th Column name from ${header[6]} should be Category Icon.`)
            isError = false
        }
        if(header[7] !== 'Category Name') {
            newErrors.push(`Please change 8th Column name from ${header[7]} should be Category Name.`)
          isError = false
        }
        if(header[8] !== 'Category ID') {
            newErrors.push(`Please change 9th Column name from ${header[8]} should be Category ID.`)
            isError = false
        }
        if(header[9] !== 'Category Items') {
            newErrors.push(`Please change 10th Column name from ${header[9]} should be Category Items.`)
            isError = false
        }
        if(header[10] !== 'Category Bundles') {
            newErrors.push(`Please change 11th Column name from ${header[10]} should be Category Bundles.`)
            isError = false
        }
        if (header[11] !== 'Tags') {
            newErrors.push(`Please change 12th Column name from ${header[11]} to Tags.`)
            isError = false
        }
        if (header[12] !== 'Meta Data') {
            newErrors.push(`Please change 13th Column name from ${header[12]} to Meta Data.`)
            isError = false
        }
        setStoreFileErrors(newErrors);
        if(isError){
          return true
        }
    }

    const transformStoreData = (items) => {
      return items && items.reduce((acc, entry, index) => {
        const existing = acc.find(obj => obj.storeId === entry["Store ID"]);
        if (existing) {
          let contentItems = entry["Category Items"] && entry["Category Items"].split(', ').map(pair => {
            const [itemId, value] = pair.split(/[\(\)]/);
            return {type: 'Item', clientStoreId : entry["Store ID"], clientCategoryId : entry["Category ID"], itemId: ItemList && ItemList.filter(item => item.itemId == itemId).map(item => item.id)[0] , contentId : itemId, quantity : ((value !== '' && value !== null) ? parseInt(value) : null), storeContentPrice: [], errors: {contentIdErr: [], quantityErr: []}};
          });
          let contentBundles = entry["Category Bundles"] && entry["Category Bundles"].split(', ').map(pair => {
            const [bundleId, value] = pair.split(/[\(\)]/);
            return {type: 'Bundle', clientStoreId : entry["Store ID"], clientCategoryId : entry["Category ID"], bundleId: BundleList && BundleList.filter(item => item.bundleId == bundleId).map(item => item.id)[0], contentId : bundleId, quantity : ((value !== '' && value !== null) ? parseInt(value) : null), storeContentPrice: [], errors: {contentIdErr: [], quantityErr: []}};
          });
          existing.storeCategories.push({isDefault: false, iconUrl: entry["Category Icon"], name: entry["Category Name"], storeCategoryId: entry["Category ID"], description: null, storeClientId: entry["Store ID"], storeContents: [...(contentItems ?? []), ...(contentBundles ?? [])]})
        }else{
          let contentItems = entry["Category Items"] && entry["Category Items"].split(', ').map(pair => {
            const [itemId, value] = pair.split(/[\(\)]/);
            return {type: 'Item', clientStoreId : entry["Store ID"], clientCategoryId : entry["Category ID"], itemId: ItemList && ItemList.filter(item => item.itemId == itemId).map(item => item.id)[0] , contentId : itemId, quantity : ((value !== '' && value !== null) ? parseInt(value) : null), storeContentPrice: [], errors: {contentIdErr: [], quantityErr: []}};
          });
          let contentBundles = entry["Category Bundles"] && entry["Category Bundles"].split(', ').map(pair => {
            const [bundleId, value] = pair.split(/[\(\)]/);
            return {type: 'Bundle', clientStoreId : entry["Store ID"], clientCategoryId : entry["Category ID"], bundleId: BundleList && BundleList.filter(item => item.bundleId == bundleId).map(item => item.id)[0], contentId : bundleId, quantity : ((value !== '' && value !== null) ? parseInt(value) : null), storeContentPrice: [], errors: {contentIdErr: [], quantityErr: []}};
          });
          acc.push({ 
            projectId : appid,
            iconUrl : ((entry["Store Icon"] !== null && entry["Store Icon"] !== '' && entry["Store Icon"] !== 'N/A')  ? entry["Store Icon"] : null) ,
            name : entry["Display Name"],
            storeId : entry["Store ID"],
            description : ((entry["Store Description"] !== null && entry["Store Description"] !== '') ? entry["Store Description"] : null),
            storePlatformIds: ((entry["Store Platform"] !== null && entry["Store Platform"] !== '') ? (entry["Store Platform"].split(', ')).map(e => (e === 'android' || e === 'Android') ? 1 : (e === 'iOS' || e === 'ios') ? 2 : null).filter(item => item !== null) : null),
            storeLocationIds: ((entry["Store Location"] !== null && entry["Store Location"] !== '' && (entry["Store Location"] === 'Global' || entry["Store Location"] === 'global')) ?  (Array.from({ length: 249 }, (_, index) => index + 1)) : (entry["Store Location"] !== null && entry["Store Location"] !== '' && (entry["Store Location"] !== 'Global' || entry["Store Location"] !== 'global')) ? locationOption && locationOption.filter(e => entry["Store Location"].includes(e.name)).map(x => x.id) : null),
            storeCategories : [{isDefault: true, iconUrl: ((entry["Category Icon"] !== null && entry["Category Icon"] !== '' && entry["Category Icon"] !== 'N/A') ? entry["Category Icon"]  : null), name: entry["Category Name"], storeCategoryId: entry["Category ID"], description: null, storeClientId: entry["Store ID"], storeContents: [...(contentItems ?? []), ...(contentBundles ?? [])]}],
            rewardUnlockCondition : null,
            meta : ((entry["Meta Data"] && entry["Meta Data"] !== null && entry["Meta Data"] !== '' && entry["Meta Data"] !== 'N/A') ? JSON.parse(entry["Meta Data"]) : null),
            tags : ((entry["Tags"] && entry["Tags"] !== null && entry["Tags"] !== 'N/A'  && entry["Tags"] !== '') ? entry["Tags"].split(", ") : null),
            active : true,
            archive : false,
          });
        }
        return acc;
      }, []);
    }

    const convertStoreToJson = async(data, headers) => {
      if(data.length > 1000){
        setStoreFileErrors([`Total number of rows should be less than 1000.`])
      }else{
        const dataRows = data && data.map(row => {
        // Create an object with the headers and corresponding row values
          const rowData = {};
          headers.forEach((header, index) => {
            rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
          });
          return rowData;
        })
        return transformStoreData(dataRows)
      }
    }

    const checkStoreFileDataType = (obj) => {
      let errors = {iconErr: [], nameErr: [], idErr: [], descriptionErr: [], platformErr: [], locationErr: []}
      if(obj.iconUrl !== null && typeof obj.iconUrl !== 'string'){
        errors.iconErr.push(`Store Icon should be a valid string.`);
      }
      if(typeof obj.name !== 'string'){
        errors.nameErr.push(`Display Name should be a valid string.`);
      }
      if(typeof obj.storeId !== 'string'){
        errors.idErr.push(`Store ID should be a valid string.`);
      }
      if(obj.description !== null &&  typeof obj.description !== 'string'){
        errors.descriptionErr.push(`Store Description should be a valid string.`);
      }
      return errors;
    }

    const checkCategoryDataType = (obj) => {
      let errors = {catIconErr: [], catNameErr: [], catIdErr: [], catDescriptionErr: []}
      if(obj.iconUrl !== null && typeof obj.iconUrl !== 'string'){
        errors.catIconErr.push(`Category Icon should be a valid string.`);
      }
      if(typeof obj.name !== 'string'){
        errors.catNameErr.push(`Category Name should be a valid string.`);
      }
      if(typeof obj.storeCategoryId !== 'string'){
        errors.catIdErr.push(`Category ID should be a valid string.`);
      }
      if(obj.description !== null &&  typeof obj.description !== 'string'){
        errors.catDescriptionErr.push(`Category Description should be a valid string.`);
      }
      return errors;
    }

    const checkDataType = (obj) => {
      return obj && obj.map((e, i) => ({
        ...e,
        storeCategories : e.storeCategories && e.storeCategories.map((x, index) => ({
          ...x,
          errors: checkCategoryDataType(x)
        })),
        errors: checkStoreFileDataType(e)
      }))
    }

    const findCategoryByStoreCategoryId = (data, searchObj) => {
      return data.flatMap(item => item.categories).find(cat => cat.storeCategoryId === searchObj.storeCategoryId); 
    };

    const validateStore = async(rewards, data) => {
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await axios.post(`${BASE_URL}/rewards-details/validate`, rewards, headers)
      if(res.data.data){
        let content = res.data.data
        let existingStores = content.storeDetails.existingStores
        let existingData = content.storeDetails.existingStores && content.storeDetails.existingStores.map(e => e.storeId)
        if(existingData?.length > 0){
          const updatedData = data && data.map((e) => {
            let matchObj = existingData && existingData.find(x => x === e.storeId)
            let storeObj = existingStores && existingStores.find(item => item.storeId === e.storeId)
            if(matchObj){
              return{
                ...e,
                id: storeObj.id,
                method : 'PUT',
                storeCategories : e.storeCategories && e.storeCategories.map((category, i) => {
                  let matchCategory = findCategoryByStoreCategoryId(existingStores, category)
                  if(matchCategory){
                    return{
                      id: matchCategory.id,
                      ...category,
                    }
                  }else{
                    return{
                      ...category
                    }
                  }
                })
              }
            }else{
              return{
                ...e, 
                method: 'POST'
              }
            }    
          })
          return updatedData
        }else{
          return data
        }
      }
    }

    const checkContent = async(content, data) => {
      const { clientStoreId, clientCategoryId, type, contentId } = content;
      // Find the store matching the clientStoreId
      const store = data.find(store => store.storeId === clientStoreId);
      if (!store) return false;
      // Find the category within the store matching the storeCategoryId
      const category = store.categories.find(cat => cat.storeCategoryId === clientCategoryId);
      if (!category) return false;
      // Depending on type, check if the contentId exists in items or bundles
      if (type === 'Item') {
        return category.categoryContents.items.some(item => item.itemId === contentId);
      } else if (type === 'Bundle') {
        return category.categoryContents.bundles.some(bundle => bundle.bundleId === contentId);
      }
      return false;
    }

    const validateStoreContent = async(rewards, data) => {
        const token = localStorage.getItem('token')
        const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
        let res = await axios.post(`${BASE_URL}/rewards-details/validate`, rewards, headers)
        let content = res.data.data
        let existStoreData = [...content.storeDetails.existingStores]
        let nonExistingData = [...content.itemDetails.nonExistingItems, ...content.bundlesDetails.nonExistingBundles]
        let nonExistingCurrencyData = content.currenciesDetails.nonExistingCurrencies
        let nonExistingItemData = content.itemDetails.nonExistingItems
        let nonExistingBundleData = content.bundlesDetails.nonExistingBundles
        let updatedData = data
        if(existStoreData?.length > 0){
          updatedData = await Promise.all(updatedData && updatedData.map(async e => ({
            ...e,
            storeCategories: await Promise.all(e.storeCategories && e.storeCategories.map(async x => ({
              ...x,
              storeContents: await Promise.all(x.storeContents && x.storeContents.map(async content => {
                let matchContent = await checkContent(content, existStoreData)
                if(matchContent){
                  return{
                    ...content,
                    errors: {
                      ...content.errors,
                      contentIdErr : [
                        ...(content.errors && content.errors.contentIdErr ? content.errors.contentIdErr : []), `Content Id with ${content.contentId} already exist in this category.`
                      ]
                    }
                  }
                }else{
                  return{
                    ...content,
                    errors: {
                      ...content.errors,
                      contentIdErr: content.errors && content.errors.contentIdErr ? [...content.errors.contentIdErr] : [] 
                    }
                  }
                }
              }))
            })))
          }))
          )
        }
        if(nonExistingData?.length > 0){
          updatedData = updatedData && updatedData.map(e => ({
            ...e,
            storeCategories : e.storeCategories && e.storeCategories.flatMap(x => ({
              ...x,
              storeContents : x.storeContents && x.storeContents.flatMap(content => {
                if(content.type === 'Item'){
                  let matchObj = nonExistingItemData && nonExistingItemData.find(id => id === content.contentId)
                  if(matchObj){
                    return{
                      ...content,
                      errors: {
                        ...content.errors,
                        contentIdErr : [
                          ...(content.errors && content.errors.contentIdErr ? content.errors.contentIdErr : []), `Content Id with ${content.contentId} does not exist in your project.`
                        ]
                      }
                    }
                  }else{
                    return{
                      ...content,
                      errors: {
                        ...content.errors,
                        contentIdErr: content.errors && content.errors.contentIdErr ? [...content.errors.contentIdErr] : [] 
                      }
                    }
                  }
                }
                if(content.type === 'Bundle'){
                  let matchObj = nonExistingBundleData && nonExistingBundleData.find(id => id === content.contentId)
                  if(matchObj){
                    return{
                      ...content,
                      errors: {
                        ...content.errors,
                        contentIdErr : [
                          ...(content.errors && content.errors.contentIdErr ? content.errors.contentIdErr : []), `Content Id with ${content.contentId} does not exist in your project.`
                        ]
                      }
                    }
                  }else{
                    return{
                      ...content,
                      errors: {
                        ...content.errors,
                        contentIdErr: content.errors && content.errors.contentIdErr ? [...content.errors.contentIdErr] : [] 
                      }
                    }
                  }
                }
              })
            }))
          }))
        }
        if(nonExistingCurrencyData?.length > 0){
          updatedData = updatedData && updatedData.flatMap(store => ({
            ...store,
            storeCategories: store.storeCategories && store.storeCategories.flatMap(category => ({
              ...category,
              storeContents: category.storeContents && category.storeContents.flatMap(content => ({
                ...content,
                storeContentPrice : content.storeContentPrice && content.storeContentPrice.map(price => {
                  let matchObj = nonExistingCurrencyData && nonExistingCurrencyData.find(id => id === price.currencyClientID)
                  if(matchObj){
                    return{
                      ...price,
                      errors: {
                        ...price.errors,
                        currencyIdErr : [
                          ...(price.errors && price.errors.currencyIdErr ? price.errors.currencyIdErr : []), `Currency Id with ${price.currencyClientID} does not exist in your project.`
                        ]
                      }
                    }
                  }else{
                    return{
                      ...content,
                      errors: {
                        ...content.errors,
                        currencyIdErr: price.errors && price.errors.currencyIdErr ? [...price.errors.currencyIdErr] : [] 
                      }
                    }
                  }
                })
              }))
            }))
          }))
      }
      return updatedData
  }
    
    const checkRewards = async(data, type) => {
      if(type === 'Stores'){
        const rewards = {
          projectId : appid,
          rewardDetails : {
            items : {ids:[]},
            currencies : {ids:[]},
            bundles : {ids:[]},
            progressionMarkers : {ids:[]},
            progressionSystem: {ids: []},
            stores : {ids: []}
          }
        }
        data && data.forEach((e, index) => {
          if(type === 'Stores' && e.storeId && e.storeId !== '' && e.storeId !== null && e.storeId != 'N/A' && !rewards.rewardDetails.stores.ids.includes(e.storeId)){
            rewards.rewardDetails.stores.ids.push((e.storeId).toString());
          }                
        })
        let validateRewardData = await validateStore(rewards, data)
        return validateRewardData
      }else{
        const rewards = {
          projectId : appid,
          rewardDetails : {
            items : {ids:[]},
            currencies : {ids:[]},
            bundles : {ids:[]},
            progressionMarkers : {ids:[]},
            progressionSystem: {ids: []},
            stores : {ids: []}
          }
        }
          
        data && data.forEach(item => {
          if(!rewards.rewardDetails.stores.ids.includes(item.storeId)){
            rewards.rewardDetails.stores.ids.push(item.storeId);
          }
          item.storeCategories && item.storeCategories.map(e => {
            e.storeContents && e.storeContents.forEach(x => {
              if(x.type === 'Item' && !rewards.rewardDetails.items.ids.includes(x.contentId)){
                rewards.rewardDetails.items.ids.push(x.contentId);
              }
              if(x.type === 'Bundle' && !rewards.rewardDetails.bundles.ids.includes(x.contentId)){
                rewards.rewardDetails.bundles.ids.push(x.contentId);
              }
              x.storeContentPrice && x.storeContentPrice.forEach(price => {
                if(price.currencyClientID && price.currencyClientID !== null && !rewards.rewardDetails.currencies.ids.includes(price.currencyClientID)){
                  rewards.rewardDetails.currencies.ids.push(price.currencyClientID);
                }
              })
            })
          })        
        })
        let validateRewardData = await validateStoreContent(rewards, data)
        return validateRewardData
      }
    }

    const validateStoreCSV = () => {
        return new Promise((resolve, reject) => {
            if(storeFile && (storeFile.size/1024).toFixed(2) < 10000){
                const fileExtension = storeFile.name.split('.').pop().toLowerCase();
                const reader = new FileReader();
                reader.onload = async(e) => {
                    if(fileExtension === 'csv'){
                    const data = e.target.result;
                    const workbook = XLSX.read(data, { type: 'string', raw: true });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
                    const headers = columnsArray[0];
                    if(checkErrors(headers)){
                        if(pricingFile === null){
                          resolve(true);
                        }
                        const json = XLSX.utils.sheet_to_json(worksheet);
                        let storeJSON = await convertStoreToJson(json, headers)
                        let storeDataTypeJSON = checkDataType(storeJSON)
                        let checkRewardData = await checkRewards(storeDataTypeJSON, 'Stores')
                        if(checkRewardData){
                          setStoreData(checkRewardData)
                        }
                        if(checkRewardData && pricingFile){
                          let resultPricing = await validatePricingCSV(checkRewardData);
                          if(!lockBy){
                            resolve(resultPricing.status)
                          }else{
                            resolve(true)
                          }
                        }else{
                          resolve(true)
                        }
                        // if(checkRewardData && pricingFile){
                        //     let resultPricing = await validatePricingCSV(checkRewardData);
                        //     if(!lockByFile){
                        //       resolve(resultPricing.status)
                        //     }else{
                        //       resolve(true)
                        //     }
                        //     // else{
                        //     //   let resultLockBy = await validateLockByCSV(resultPricing.data)
                        //     //   resolve(resultPricing.status && resultLockBy)
                        //     // }
                        // }
                        // else{
                        //   resolve(true)
                        //   // let resultLockBy = await validateLockByCSV(checkRewardData)
                        //   // resolve(resultLockBy)
                        // }
                    }
                    }
                    else{
                    const arrayBuffer = e.target.result;
                    const data = new Uint8Array(arrayBuffer);
                    const workbook = XLSX.read(data, { type: 'array' });
                    const sheetName = workbook.SheetNames[0];
                    const worksheet = workbook.Sheets[sheetName];
                    const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
                    const headers = columnsArray[0];
                    if(checkErrors(headers)){
                        if(pricingFile === null){
                          resolve(true);
                        }
                        const json = XLSX.utils.sheet_to_json(worksheet);
                        let storeJSON = await convertStoreToJson(json, headers)
                        let storeDataTypeJSON = checkDataType(storeJSON)
                        let checkRewardData = await checkRewards(storeDataTypeJSON, 'Stores')
                        if(checkRewardData){
                          setStoreData(checkRewardData)
                        }
                        if(checkRewardData && pricingFile){
                          let resultPricing = await validatePricingCSV(checkRewardData);
                          if(!lockBy){
                            resolve(resultPricing.status)
                          }else{
                            resolve(true)
                          }
                        }else{
                          resolve(true)
                        }
                      }
                    }
                };  
                if(fileExtension === 'csv'){
                  reader.readAsText(storeFile);
                }else{
                  reader.readAsArrayBuffer(storeFile);
                }
            }
        })
    }

    // 2 Pricing File

    const checkPricingError = (header) => {
        let newErrors = []
        let isError = true;
        if (header[0] !== 'Store ID') {
          newErrors.push(`Please change 1st Column name from ${header[0]} to Store ID.`);
          isError = false
        }
        if (header[1] !== 'Category ID') {
          newErrors.push(`Please change 2nd Column name from ${header[1]} to Category ID.`);
          isError = false
        }
        if (header[2] !== 'Item ID') {
          newErrors.push(`Please change 3rd Column name from ${header[2]} to Item ID.`);
          isError = false
        }
        if (header[3] !== 'Bundle ID') {
          newErrors.push(`Please change 4th Column name from ${header[3]} to Bundle ID.`);
          isError = false
        }
        if(header[4] !== 'Currency Type'){
            newErrors.push(`Please change 5th Column name from ${header[4]} should be Currency Type`)
            isError = false
        }
        if(header[5] !== 'Currency ID'){
            newErrors.push(`Please change 6th Column name from ${header[5]} should be Currency ID`)
            isError = false
        }
        if(header[6] !== 'Real World Currency ID'){
            newErrors.push(`Please change 7th Column name from ${header[6]} should be Real World Currency ID`)
            isError = false
        }
        if (header[7] !== 'Price Amount') {
          newErrors.push(`Please change 8th Column name from ${header[7]} to Price Amount.`);
          isError = false
        }
        if (header[8] !== 'Discount') {
          newErrors.push(`Please change 9th Column name from ${header[8]} to Discount.`);
          isError = false
        }
        if (header[9] !== 'Bonus Cash Allowance (RMG Only)') {
          newErrors.push(`Please change 10th Column name from ${header[9]} to Bonus Cash Allowance.`);
          isError = false
        }
        if (header[10] !== 'Select Platform (IAP Only)') {
          newErrors.push(`Please change 11th Column name from ${header[10]} to Select Platform (IAP Only).`);
          isError = false
        }
        if (header[11] !== 'Product ID (IAP Only)') {
          newErrors.push(`Please change 12th Column name from ${header[11]} to Product ID.`);
          isError = false
        }
        setPricingFileErrors(newErrors)
        if(isError){
          return true
        }
    }

    const parseValidInt = (str) => {
      if (!isNaN(str) && !isNaN(parseInt(str))) {
        return parseInt(str);
      }
      return str;
    }

    const transformPricingData = (data, items) => {
        const filterPricing = data && data.map((entry, index) => ({
          clientStoreId : entry["Store ID"],
          clientCategoryId : entry["Category ID"],
          contentId : ((entry["Item ID"] && entry["Item ID"] !== null && entry["Item ID"] !== '' && (entry["Bundle ID"] === null || entry["Bundle ID"] === '')) ? entry["Item ID"].toString() : ((entry["Bundle ID"] && entry["Bundle ID"] !== null && entry["Bundle ID"] !== '' && (entry["Item ID"] !== null || entry["Item ID"] !== ''))) ? entry["Bundle ID"].toString() : null),
          priceTypes : (entry["Currency Type"] === 'Virtual' ? 'virtual currency' : entry["Currency Type"] === 'Real Money Gaming Currency' ? 'RMG' : entry["Currency Type"] === 'IAP' ? 'IAP' : ''),
          ...(entry["Currency ID"] && entry["Currency ID"] !== null && entry["Currency ID"] !== 'N/A' && entry["Currency ID"] !== '' && {currencyClientID : entry["Currency ID"]}),
          ...(entry["Currency ID"] && entry["Currency ID"] !== null && entry["Currency ID"] !== 'N/A' && entry["Currency ID"] !== '' && {currencyId : currencyList && currencyList.filter(item => item.currencyId == entry["Currency ID"]).map(item => item.id)[0]}),
          ...({discount : ((entry["Discount"] !== null && entry["Discount"] !== 'N/A'  && entry["Discount"] !== '') ? entry["Discount"] : null)}),
          ...({price : ((entry["Price Amount"] != null && entry["Price Amount"] != 'N/A' && entry["Price Amount"] != '') ? (entry["Price Amount"]).toString() : "0")}),
          ...(entry["Bonus Cash Allowance (RMG Only)"] && entry["Bonus Cash Allowance (RMG Only)"] !== null && entry["Bonus Cash Allowance (RMG Only)"] !== 'N/A' && entry["Bonus Cash Allowance (RMG Only)"] !== '' && {bonusCashAllowance : parseValidInt(entry["Bonus Cash Allowance (RMG Only)"])}),
          ...(entry["Real World Currency ID"] && entry["Real World Currency ID"] !== null && entry["Real World Currency ID"] !== 'N/A' && entry["Real World Currency ID"] !== '' && {realWorldCurrencyId : realWorldCurrency && realWorldCurrency.filter(item => item.name.includes(entry["Real World Currency ID"])).map(item => item.realWorldCurrencyId)[0]}),
          ...(entry["Select Platform (IAP Only)"] && entry["Select Platform (IAP Only)"] !== null && entry["Select Platform (IAP Only)"] !== 'N/A' && entry["Select Platform (IAP Only)"] !== '' && {gamePlatformMasterId : (entry["Select Platform (IAP Only)"] === 'Android' ? 1 : entry["Select Platform (IAP Only)"] === 'iOS' ? 2 : entry["Select Platform (IAP Only)"])}),
          ...(entry["Product ID (IAP Only)"] && entry["Product ID (IAP Only)"] !== null && entry["Product ID (IAP Only)"] !== 'N/A' && entry["Product ID (IAP Only)"] !== '' && {productId : entry["Product ID (IAP Only)"]})
        }));
        
        return items && items.flatMap(stores => {
          return {
            ...stores,
            storeCategories : stores.storeCategories && stores.storeCategories.flatMap(content => {
              return{
                ...content,
                storeContents : content.storeContents && content.storeContents.map(e => {
                  const matchingPrices = filterPricing && filterPricing.filter(price =>
                    price.clientCategoryId === e.clientCategoryId &&
                    price.clientStoreId === e.clientStoreId &&
                    price.contentId === e.contentId
                  ).map(price => ({
                    contentId: price.contentId,
                    currencyClientID: price.currencyClientID,
                    priceTypes: price.priceTypes,
                    price: ((price.price !== null && price.price !== '') ? parseValidInt(price.price) : null),
                    discount: ((price.discount !== null && price.discount !== '') ? parseValidInt(price.discount) : null),
                    bonusCashAllowance: ((price.bonusCashAllowance && price.bonusCashAllowance !== null && price.bonusCashAllowance !== '') ? parseValidInt(price.bonusCashAllowance) : null),
                    gamePlatformMasterId: ((price.gamePlatformMasterId !== null && price.gamePlatformMasterId !== '') ? price.gamePlatformMasterId : null),
                    currencyId: ((price.priceTypes === 'virtual currency' || price.priceTypes === 'RMG') ? price.currencyId : null),
                    realWorldCurrencyId: (price.priceTypes === 'IAP' ? price.realWorldCurrencyId : null)
                  }))
                  return {
                    ...e,
                    storeContentPrice: [...e.storeContentPrice, ...matchingPrices]
                  };
                })
              }
            })
    
          }
        })
    
    }

    const convertPricingToJson = async(data, headers, items) => {
        if(data.length > 1000){
          setPricingFileErrors([`Total number of rows should be less than 1000.`])
        }else{
          const dataRows = data && data.map(row => {
            // Create an object with the headers and corresponding row values
            const rowData = {};
            headers.forEach((header, index) => {
              rowData[header] = row[header] !== undefined ? row[header] : null; // Handle blank cells
            });
            return rowData;
          })
          return transformPricingData(dataRows, items)
        }
    }

    const checkPricingFileDataType = (obj) => {
        let errors = {amountErr: [], currencyTypeErr: [], currencyIdErr: [], realWorldCurrencyErr: [], amountErr: [], discountErr: [], BCAErr: [], platformErr: [], productErr: []}

        if(obj.price !== null && typeof obj.price !== 'number'){
          errors.amountErr.push(`Currency Price should be a valid integer.`)
        }

        if(typeof obj.priceTypes !== 'string'){
          errors.currencyTypeErr.push(`Currency Type should be a valid string.`)
        }

        if(obj.discount !== null && typeof obj.discount !== 'number'){
          errors.discountErr.push(`Discount should be a valid integer.`)
        }

        if(obj.bonusCashAllowance !== null && obj.bonusCashAllowance !== undefined && (typeof obj.bonusCashAllowance !== 'number' || !Number.isInteger(obj.bonusCashAllowance))){
          errors.BCAErr.push(`Bonus Cash Allowance should be a valid integer.`)
        }
        if(obj.gamePlatformMasterId !== null && obj.gamePlatformMasterId !== undefined && (typeof obj.gamePlatformMasterId !== 'number' || !Number.isInteger(obj.gamePlatformMasterId))){
          errors.platformErr.push(`Select Platform (IAP Only) should be a valid string.`)
        }
    
        if(obj.productId !== undefined && typeof obj.productId !== 'string'){
          errors.productErr.push(`Product ID should be a valid string.`)
        }
    
        return errors
    }
    
    const checkContentErrors = (obj) => {
      let errors = {contentIdErr: [], quantityErr: []}  
      if(typeof obj.contentId !== 'string'){
        errors.contentIdErr.push(`Conetnt Id should be a valid string.`)
      }
      if(obj.quantity !== null && typeof obj.quantity !== 'number'){
        errors.quantityErr.push(`Quantity should be a valid integer.`)
      }
      return errors
    }

    const checkPricingDataType = (obj) => {
      return obj && obj.map(item => ({
        ...item,
        storeCategories: item.storeCategories && item.storeCategories.map(e => ({
          ...e,
          storeContents : e.storeContents && e.storeContents.map(content => ({
            ...content,
            errors: checkContentErrors(content),
            storeContentPrice: content.storeContentPrice && content.storeContentPrice.map(price => ({
              ...price,
              errors: checkPricingFileDataType(price)
            }))
          }))
        }))
      }))
    }

    const validatePricingCSV = (items) => {
      return new Promise((resolve, reject) => {
        if(pricingFile && (pricingFile.size/1024).toFixed(2) < 10000){
          const fileExtension = pricingFile.name.split('.').pop().toLowerCase();
          const reader = new FileReader();
          reader.onload = async(e) => {
            if(fileExtension === 'csv'){
              const data = e.target.result;
              const workbook = XLSX.read(data, { type: 'string', raw: true });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {header: 1, defval: ''});
              const headers = columnsArray[0];
              if(checkPricingError(headers)){
                const json = XLSX.utils.sheet_to_json(worksheet);
                let pricingJSON = await convertPricingToJson(json, headers, items)
                let pricingInfo = await checkPricingDataType(pricingJSON)
                let checkPricing = await checkRewards(pricingInfo, 'Pricing')
                if(checkPricing){
                  resolve({status: true, data: checkPricing});
                  setStoreData(checkPricing)
                }
              }else{
                resolve({status: false, data: []});
              }
            }else{
              const arrayBuffer = e.target.result;
              const data = new Uint8Array(arrayBuffer);
              const workbook = XLSX.read(data, { type: 'array' });
              const sheetName = workbook.SheetNames[0];
              const worksheet = workbook.Sheets[sheetName];
              const columnsArray = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], { header: 1 });
              const headers = columnsArray[0];
              if(checkPricingError(headers)){
                const json = XLSX.utils.sheet_to_json(worksheet);
                let pricingJSON = await convertPricingToJson(json, headers, items)
                let pricingInfo = await checkPricingDataType(pricingJSON)
                let checkPricing = await checkRewards(pricingInfo, 'Pricing')
                if(checkPricing){
                  resolve({status: true, data: checkPricing});
                  setStoreData(checkPricing)
                }
              }else{
                resolve({status: false, data: []});
              }
            }
          };  
          if(fileExtension === 'csv'){
            reader.readAsText(pricingFile);
          }else{
            reader.readAsArrayBuffer(pricingFile);
          }
        }else if(pricingFile && (pricingFile.size/1024).toFixed(2) > 10000){
          setPricingFileErrors([`File Size Should be less than 10Mb`])
          resolve({status: false, data: []});
        }
      })
    }

  // Export Data

  const [exportOptions, setExportOptions] = useState([{id : 1, name : 'As XLSX'}, {id : 2, name: 'As CSV'}])

  const transformStoresData = (data) => {
    // Filter out items with null itemId
    return data && data.flatMap(item =>
      item.storesCategories && item.storesCategories.map(category => ({
        "Store Icon" : item.iconUrl,
        "Display Name" : item.name,
        "Store ID" : item.storeId,
        "Store Description" : item.description,
        "Store Platform" : ((item.storePlatforms !== null && item.storePlatforms?.length > 0) ? item.storePlatforms && item.storePlatforms.map(platform => platform.storePlatformMaster.name).join(', ') : null),
        "Store Location" : ((item.storeLocations !== null && item.storeLocations?.length > 0) ? item.storeLocations && item.storeLocations.map(location => location.storeLocationMaster.name).join(', ') : null),
        "Category Icon" : category.iconUrl,
        "Category Name" : category.name,
        "Category ID" : category.storeCategoryId,
        "Category Items" : (category.storesContents && category.storesContents.filter(x => x.itemId !== null)?.length > 0 ? category.storesContents && category.storesContents.filter(x => x.itemId !== null).map(e => {return `${e.item.itemId}(${e.quantity !== null ? e.quantity : ''})`}).join(', ') : null),
        "Category Bundles" : (category.storesContents && category.storesContents.filter(x => x.bundleId !== null)?.length > 0 ? category.storesContents && category.storesContents.filter(x => x.bundleId !== null).map(e => {return `${e.bundle.bundleId}(${e.quantity !== null ? e.quantity : ''})`}).join(', ') : null),
        "Tags" : ((item.tagsMapping !== null && item.tagsMapping?.length > 0) ? item.tagsMapping && item.tagsMapping.map(mapping => mapping?.tags?.name).join(', ') : 'N/A'),
        "Meta Data" : JSON.stringify(item.meta)
      }))
    )
  }

  const transformStoresPricingData = (data) => {
    const headers = [
      'Store ID',
      'Category ID',
      'Item ID',
      'Bundle ID',
      'Currency Type',
      'Currency ID',
      'Real World Currency ID',
      'Price Amount',
      'Discount',
      'Bonus Cash Allowance (RMG Only)',
      'Select Platform (IAP Only)',
      'Product ID (IAP Only)',
    ];
    const result = [];
    data && data.forEach(store => {
      store.storesCategories && store.storesCategories.forEach(category => {
        category.storesContents && category.storesContents.forEach(content => {
          content.storeContentPrice && content.storeContentPrice.forEach(price => {
            result.push({
              "Store ID": store.storeId,
              "Category ID": ((category.storeCategoryId !== null && category.storeCategoryId !== '') ? category.storeCategoryId : null),
              "Item ID" : ((content.itemId !== null && content.bundleId === null) ? content.item.itemId : null),
              "Bundle ID" : ((content.itemId === null && content.bundleId !== null) ? content.bundle.bundleId : null),
              "Currency Type": (price.priceTypes === 'virtual currency' ? 'Virtual' : price.priceTypes === 'RMG' ? 'Real Money Gaming Currency' : price.priceTypes === 'IAP' ? 'IAP' : 'N/A'),
              "Currency ID": ((price.currency !== null && price.currency.currencyId !== null && price.currency.currencyId !== '') ? price.currency.currencyId : (price.currency !== null && (price.currency.currencyId === null || price.currency.currencyId === '')) ? 'N/A' : price.currency === null ? 'N/A' : ''),
              "Real World Currency ID": ((price.realWorldCurrency !== null && price.realWorldCurrencyId !== null && price.realWorldCurrencyId !== '') ? price.realWorldCurrency.code : (price.realWorldCurrency !== null && (price.realWorldCurrencyId === null || price.realWorldCurrencyId === '')) ? 'N/A' : price.realWorldCurrency === null ? 'N/A' : ''),
              "Price Amount": ((price.price !== null && price.price !== '') ? price.price : (price.price === null || price.price === '') ? 'N/A' : ''),
              "Discount": ((price.discount !== null && price.discount !== '') ? price.discount : (price.discount === null || price.discount === '') ? 'N/A' : ''),
              "Bonus Cash Allowance (RMG Only)": ((price.bonusCashAllowance !== null && price.bonusCashAllowance !== '') ? price.bonusCashAllowance : (price.bonusCashAllowance === null || price.bonusCashAllowance === '') ? 'N/A' : ''),
              "Select Platform (IAP Only)": ((price.gamePlatformMasterId !== null && price.gamePlatformMasterId !== '' && price.gamePlatformMasterId === 1) ? 'Android' : (price.gamePlatformMasterId !== null && price.gamePlatformMasterId !== '' && price.gamePlatformMasterId === 2) ? 'iOS' : (price.gamePlatformMasterId === null || price.gamePlatformMasterId === '') ? 'N/A' : ''),
              "Product ID (IAP Only)": ((price.productId !== null && price.productId !== '') ? price.productId : (price.productId === null || price.productId === '') ? 'N/A' : ''),
            });
          });
        });
      });
    });
    if(result?.length === 0) {
      result.push(headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {}));
    }
    return result
  }

  const transformStoresLockByData = (data) => {
    const headers = [
      'Store ID',
      'Lock By Item',
      'Lock By Bundle',
      'Lock By Progression Systems',
      'Lock By Progression System Levels',
    ];
    let result = [];
    data && data.forEach(item => {
      item.rewardsUnlockConditions && item.rewardsUnlockConditions.forEach(condition => {
        if (condition.unlockItemId) {
          result.push({
            "Item ID" : item.itemId,
            'Lock By Item': condition.item.itemId,
            'Lock By Bundle' : null,
            'Lock By Progression Systems': null,
            'Lock By Progression System Levels': null
          });
        }else if (condition.unlockBundleId) {
          result.push({
            "Item ID" : item.itemId,
            'Lock By Item': null,
            'Lock By Bundle' : condition.bundle.bundleId,
            'Lock By Progression Systems': null,
            'Lock By Progression System Levels': null
          });
        }
        else if (condition.unlockLevelSystemId) {
          result.push({
            "Item ID" : item.itemId,
            'Lock By Item': null,
            'Lock By Bundle' : null,
            'Lock By Progression Systems': condition.unlockLevel.levelSystemId,
            'Lock By Progression System Levels': condition.lockedLevel
          });
        }
      });
    });
    if(result?.length === 0) {
      result.push(headers.reduce((acc, header) => ({ ...acc, [header]: '' }), {}));
    }
    return result;
  }

  const getStoreDetails = async() => {
    let exportedData = []
    let ids;
    let count = Math.ceil(totalStore/100)
    for(var i = 0; i < count ; i++){
      let items = {
        projectId : appid,
        offset : (i === 0 ? 0 : (i*100)),
        limit : 100
      }
      const token = localStorage.getItem('token')
      const headers = {headers : {"Authorization" : `Bearer ${token}`, "Api-Key" : (localStorage.getItem('environment') == 'Production' ? JSON.parse(localStorage.getItem('apiKeys')).productionAPIKey : localStorage.getItem('environment') == 'Staging' ? JSON.parse(localStorage.getItem('apiKeys')).stagingAPIKey : JSON.parse(localStorage.getItem('apiKeys')).devAPIKey)}}
      let res = await customAxios.post(BASE_URL + GET_STORES, items, headers)
      if(res){
        let storeInstance = res.data.data.stores
        ids = storeInstance && storeInstance.map(e => e.id)
        exportedData.push(...storeInstance)
      }
    }
    if(exportedData){
      return {data : exportedData, ids: ids}
    }
  }

  const exportData = async(type) => {
    let storeData = await getStoreDetails()
    let convertStore = await transformStoresData(storeData.data)
    let convertStorePricing = await transformStoresPricingData(storeData.data)
    let convertStoreLockBy = await transformStoresLockByData(storeData.data)
    // Stores
    const worksheetStores = XLSX.utils.json_to_sheet(convertStore);
    const workbookStores = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookStores, worksheetStores, "Sheet1");
    // Store Pricing
    const worksheetPricing = XLSX.utils.json_to_sheet(convertStorePricing);
    const workbookPricing = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookPricing, worksheetPricing, "Sheet1");
    // Store Lock By
    const worksheetLockBy = XLSX.utils.json_to_sheet(convertStoreLockBy);
    const workbookLockBy = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbookLockBy, worksheetLockBy, "Sheet1");
    if(type === 'As XLSX'){
      XLSX.writeFile(workbookStores, "Store_Details.xlsx");
      XLSX.writeFile(workbookPricing, "Store_Pricing.xlsx");
      XLSX.writeFile(workbookLockBy, "Store_Lock_By.xlsx");
    }else{
      XLSX.writeFile(workbookStores, "Store_Details.csv");
      XLSX.writeFile(workbookPricing, "Store_Pricing.csv");
      XLSX.writeFile(workbookLockBy, "Store_Lock_By.csv");
    }
  }

  // Re-Validate

  const revalidate = async(data) => {
    let storeDataTypeJSON = await checkDataType(data)
    let checkRewardData = await checkRewards(storeDataTypeJSON, 'Stores')
    if(checkRewardData){
      setStoreData(checkRewardData)
      if(pricingFile){
        let pricingInfo = await checkPricingDataType(checkRewardData)
        let checkPricing = await checkRewards(pricingInfo, 'Pricing')
        setStoreData(checkPricing)
        // if(lockByFile){
        //   let lockByDataCheck = await checkLockByDataType(checkPricing)
        //   let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
        //   setBundleData(checkRewardData)
        // }
      }else if(lockByFile){
        // let lockByDataCheck = await checkLockByDataType(checkBundles)
        // let checkRewardData = await checkLockByItemRewards(lockByDataCheck)
        // setBundleData(checkRewardData)
      }
    }
  }

  // Upload

  const [bulkUpload, setBulkUpload] = useState(false)
  const [lockByStoreData, setLockByStoreData] = useState([])
  const [lockBy, setLockBy] = useState(false)

  const removeKeys = (obj, keysToRemove) => {
    if (Array.isArray(obj)) {
      return obj.map(item => removeKeys(item, keysToRemove));
    } else if (typeof obj === 'object' && obj !== null) {
        return Object.fromEntries(
        Object.entries(obj).filter(([key]) => !keysToRemove.includes(key))
        .map(([key, value]) => [key, removeKeys(value, keysToRemove)])
      );
    }
    return obj;
  };

  const removeErrorKeyObject = (filterStores) => {
    const filterStoreData = filterStores && filterStores.map(store => ({
      ...removeKeys(store, ['errors']),
      storeCategories: store.storeCategories.map(category => ({
        ...removeKeys(category, ['storeClientId', 'errors']),
        storeContents: category.storeContents.map(content => ({
          ...removeKeys(content, ['clientStoreId', 'clientCategoryId', 'contentId', 'errors']),
          storeContentPrice: content.storeContentPrice.map(price => 
            removeKeys(price, ['contentId', 'currencyClientID', 'errors'])
          ),
        })),
      })),
    }))
    return filterStoreData
  }

  const storeBulkUpload = () => {
    const segregatedStores = storeData;
    const filterStores = removeErrorKeyObject(segregatedStores)
    const storeList = {
      stores: filterStores
    }
    dispatch(startStoreApiCalls(storeList, getAllStores))
    // if(segregatedStores[0].method === 'POST'){
    //   const filterStores = removeErrorKeyObject(segregatedStores)
    //   const storeList = {
    //     stores : filterStores
    //   }
    //   console.log(storeList, "Store POST")
    //   dispatch(createStoreAction(storeList, null, appid, null))
    //   .then(res => {
    //     setStoreData(storeData.slice(1))
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // }else{
    //   const filterStores = removeErrorKeyObject(segregatedStores)
    //   const storeList = {
    //     stores : filterStores
    //   }
    //   dispatch(editStoreAction(storeList, null, appid, null))
    //   .then(res => {
    //     setStoreData(storeData.slice(1))
    //   }).catch(err => {
    //     console.log(err)
    //   })
    // }
  }

  const clearAllData = () => {
    setStoreFile(null)
    setPricingFile(null)
    setLockByFile(null)
    setStoreFileErrors(null)
    setLockByFileError(null)
    setPricingFileErrors(null)
  }

  useEffect(() => {
    if(bulkUpload){
      storeBulkUpload()
    }
  }, [bulkUpload])


  // useEffect(() => {
  //   if(bulkUpload && storeData?.length > 0){
  //     storeBulkUpload()
  //   }else if(bulkUpload && storeData?.length === 0){
  //     setBulkUpload(false)
  //     setLockBy(true)
  //     clearAllData()
  //   }
  // }, [bulkUpload, storeData])

  // useEffect(() => {
  //   if(lockBy && lockByStoreData?.length > 0){

  //   }else if(lockBy && lockByStoreData?.length === 0){
  //     getAllStores()
  //     setLockBy(false)
  //   }
  // }, [lockBy, lockByStoreData])

  const {loading, completedCalls, failedCalls, totalCalls} = useSelector((state) => state.economyStore)

  const [progress, setProgress] = useState(false)
  const [total, setTotal] = useState(0)
  const [concluded, setConcluded] = useState(0)

  const radius = 150; // Radius of the circle
  const stroke = 20; // Stroke width
  const normalizedRadius = radius - stroke * 2;
  const circumference = normalizedRadius * 2 * Math.PI;
  const progressOffset = circumference - (completedCalls / totalCalls) * circumference;
  const [action, setActions] = useState([{id: 1, name: 'Upload File', status: 'completed'}, {id: 2, name: 'Validate File', status: 'completed'}, {id: 3, name: 'Repair', status: 'completed'}, {id: 4, name: 'Import', status: 'completed'}])

  console.log(loading, 'Loading')

  // useEffect(() => {
  //   dispatch(endStoreApiCall())
  // }, [])

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  if(loading){
    return(
      <>
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : 'Stores'} classTitle={cn("title-purple", styles.title)}>
          <div className={styles.wrapper}>
          <div className={styles.progressContainer}>
            <div className={styles.progress}>
              {action && action.map((e, i) => (
                <>
                  <div className={styles.progressContent}>
                    {e.status === 'remaining' && <div className={styles.progressStatus}></div>}
                    {e.status === 'completed' && <Check />}
                    <div className={styles.content}>{e.name}</div>
                    <div className={styles.icon}><Icon name='arrow-next' size='16' /></div>
                  </div>
                </>
              ))}
            </div>
            <div className={styles.svgContainer}>
              <div className={styles.progressEvents}>
                <div className={styles.title}>Stores Uploaded</div>
                <div className={styles.events}>
                  <div className={styles.items}></div>
                  <div className={styles.eventTitle}>{completedCalls}/{totalCalls}</div>
                </div>
              </div>
              <svg height={radius * 2} width={radius * 2} className={styles.circleSvg}>
                  <circle stroke="#EFEFEF" fill="transparent" strokeWidth={stroke} r={normalizedRadius} cx={radius} cy={radius} />
                  <circle stroke="#2A85FF" fill="transparent" strokeWidth={stroke} strokeDasharray={circumference + ' ' + circumference} style={{borderRadius: '20px', strokeDashoffset: progressOffset, transition: 'stroke-dashoffset 1s ease-in-out' }} r={normalizedRadius} cx={radius} cy={radius} strokeLinecap="round" />
              </svg>
            </div>
          </div>
          </div>
        </Card>
      </>
    )
  }

  return(
    <>
      <ToastContainer />
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : "Stores"} classTitle={cn("title-purple", styles.title)}
        head={
          (
            <>
              <div className={styles.add}>
                {showSkeleton && (stores?.length > 0 || (stores?.length === 0 && isFiltered)) ?
                  <>
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={75} height={40}/>
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px', marginLeft: '10px'}} animation="wave" variant="recatangle" width={75} height={40}/>
                    <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px', marginLeft: '10px'}} animation="wave" variant="recatangle" width={95} height={40}/>
                  </>
                : !showSkeleton && (stores?.length > 0 || (stores?.length === 0 && isFiltered)) ?
                  <>
                    <ExportTooltip data={exportOptions} action={exportData}>
                      <button className={cn(styles.button, "button-white-small")}>Export</button>
                    </ExportTooltip>
                    <ControlFileUpload size='small' type='Stores' onFileChange={uploadFunctions} data={storeData} setData={setStoreData} files={files} fileErrors={fileErrors} onValidation={validateStoreCSV} setBulkUpload={setBulkUpload} revalidate={revalidate} action={getAllStores} override={getStoreDetails}/>
                    <div className={styles.createButton}>
                      <Link to={`/create-store/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                    </div>
                  </>
                : ''}
              </div>
            </>
          )
        }
      >
        <div className={styles.wrapper}>
          <div className={styles.seprator}></div>
          <div className={styles.options}>
            {showSkeleton && stores.length > 0 &&
              <>
                <div className={styles.filters}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={120} height={34}/>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={70} height={34}/>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={105} height={34}/>
                </div>

                <div className={styles.searchForm}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40} />
                </div>
              </>
            }
                    
            {!showSkeleton &&
              (stores?.length > 0 || (stores?.length === 0 && isFiltered) ?
                <>
                  <div className={styles.filters}>
                    <div className={cn(styles.buttons, {[styles.active] : showArchive})} onClick={() => setShowArchive(!showArchive)}>
                      <ArchiveSvg className={styles.icon} />
                      <span className={styles.buttonText}>Show Archived</span>
                    </div>
                    <Control setPage={setPage} filters={filters} action={getAllStores} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
                  </div>

                  <div className={styles.searchForm}>
                    <Form className={styles.form} placeholder="Search Stores" type="text" name="search" icon="search" value={search} onChange={(e) => {onSearch(e.target.value)}}/>
                  </div>
                </>
              : '')
            }
          </div>
          {/* Stores Table View */}
          {showSkeleton && stores?.length === 0 && 
            <SkeletonEmptyState />
          }
          {showSkeleton && stores?.length > 0 && 
            <SkeletonTable usedFor='Stores' rows={stores.length} column={4} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[44, 130, 130, 40]} colHeight={[44, 15, 25, 40]} />
          }
          {!showSkeleton && !isFiltered && stores?.length === 0 &&
            <EmptyState access={userAccess} route={`/create-store/${appid}`} type="Store" exportOption={<ControlFileUpload size='large' type='Stores' onFileChange={uploadFunctions} data={storeData} setData={setStoreData} files={files} fileErrors={fileErrors} onValidation={validateStoreCSV} setBulkUpload={setBulkUpload} revalidate={revalidate} action={getAllStores} override={getStoreDetails}/>}/>
          }
          {!showSkeleton && !isFiltered && stores?.length > 0 &&
            <StoreTableLayout items={stores} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (stores?.length > 0 ?
              <StoreTableLayout items={stores} title="Last edited" />
            :
              <div className={styles.message}>No Result Found</div>
            )
          }
        </div>
        {!isLoading && stores.length > 0 &&
          <div className={styles.pagination}>
            <Stack spacing={2}>
              <Pagination page={page} renderItem={(item) => (
                <PaginationItem
                  {...item}
                  sx={{
                    '&.Mui-selected': {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    },
                    '&.MuiPaginationItem-page.Mui-selected:hover' : {
                      color: 'rgb(255, 255, 255)',
                      fontWeight : 'bold',
                      border : '1px solid rgb(42, 133, 255)',
                      background : 'rgba(42, 133, 255)'
                    }
                  }}
                />
              )} siblingCount={1} count={Math.ceil(totalStore/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
        }
      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about stores <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/economy/stores" target="_blank">Click Here</a></p>
      </div>
    </>
  )

  // return(

  //   <>
  //     <ToastContainer />
  //     <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : "Stores"} classTitle={cn("title-purple", styles.title)}
  //       head={
  //         (
  //           <>
  //             <div className={styles.add}>
  //               {showSkeleton && stores.length > 0 ?
  //                 <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
  //               : !showSkeleton && stores.length > 0 ?
  //                 <>
  //                   <ExportTooltip data={exportOptions} action={exportData}>
  //                     <button className={cn(styles.button, "button-white-small")}>Export</button>
  //                   </ExportTooltip>
  //                   <ControlFileUpload size='small' type='Stores' onFileChange={uploadFunctions} data={storeData} setData={setStoreData} files={files} fileErrors={fileErrors} onValidation={validateStoreCSV} setBulkUpload={setBulkUpload} revalidate={revalidate} action={getAllStores} override={getStoreDetails}/>
  //                   <div className={styles.createButton}>
  //                     <Link to={`/create-store/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
  //                   </div>
  //                 </>
  //               : ''}
  //             </div>
  //           </>
  //         )
  //       }
  //     >
        
  //       <div className={styles.wrapper}>
  //           {stores.length > 0 && <div className={styles.seprator}></div>}
  //             <div className={styles.options}>
  //               <div className={styles.filters}>
  //                 {(showSkeleton && stores.length > 0) ?
  //                   <>
  //                     <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={74} height={34}/>
  //                     <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={130} height={34}/>
  //                   </>
  //                 : !showSkeleton && stores.length > 0 ?
  //                   <>
  //                     <div className={cn(styles.buttons, {[styles.active] : showArchive})} onClick={() => setShowArchive(!showArchive)}>
  //                       <ArchiveSvg className={styles.icon} />
  //                       <span className={styles.buttonText}>Show Archived</span>
  //                     </div>
  //                     <Control filters={filters} action={getAllStores} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
  //                   </>
  //                 : ''}
                  
  //               </div>
  //               <div className={styles.searchForm}>
  //               {showSkeleton && stores.length > 0 ?
  //                 <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40}/>
  //               : !showSkeleton && stores.length > 0 ?
  //                 <Form className={styles.form} placeholder="Search Stores" type="text" name="search" icon="search" onChange={(e) => {onSearch(e.target.value)}}/>
  //               : ''}
  //               </div>
  //           </div>
  //           {showSkeleton && 
  //             (stores.length == 0 ?
  //               <SkeletonEmptyState />
  //             : 
  //               <SkeletonTable usedFor='Stores' rows={stores.length} column={4} controls={3} type={['recatangle', 'recatangle', 'recatangle', 'circular']} colWidth={[44, 130, 130, 40]} colHeight={[44, 15, 25, 40]} />
  //             )
  //           }

  //           {!showSkeleton && 
  //             (stores.length == 0 ?
  //               <EmptyState access={userAccess} route={`/create-store/${appid}`} type="Store" exportOption={<ControlFileUpload size='large' type='Stores' onFileChange={uploadFunctions} data={storeData} setData={setStoreData} files={files} fileErrors={fileErrors} onValidation={validateStoreCSV} setBulkUpload={setBulkUpload} revalidate={revalidate} action={getAllStores} override={getStoreDetails}/>}/>
  //             : searchFlag ?
  //               <>
  //                 {isSearched && <div className={styles.textCenter}><Loader /></div>}
  //                 {!isSearched && filterStores.length == 0 && <p className={styles.textCenter}>No Result Found</p>}
  //                 {!isSearched && filterStores.length > 0 && <StoreTableLayout items={filterStores} title="Last edited" />}
  //               </>
  //             : <StoreTableLayout access={userAccess} locationOption={locationOption} items={stores} title="Last edited" />
  //             )
  //           }
            
  //           {!isLoading && stores.length > 0 &&
  //             <div className={styles.pagination}>
  //               <Stack spacing={2}>
  //                 <Pagination renderItem={(item) => (
  //                   <PaginationItem
  //                     {...item}
  //                     sx={{
  //                       '&.Mui-selected': {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       },
  //                       '&.MuiPaginationItem-page.Mui-selected:hover' : {
  //                         color: 'rgb(255, 255, 255)',
  //                         fontWeight : 'bold',
  //                         border : '1px solid rgb(42, 133, 255)',
  //                         background : 'rgba(42, 133, 255)'
  //                       }
  //                     }}
  //                   />
  //                 )} siblingCount={1} count={Math.ceil(totalStore/limit)} color="primary" onChange={handlePageChange} />
  //               </Stack>
  //             </div>
  //           }
  //         </div>

  //       {/* <div>
  //         {isLoading ?
  //           <div className={styles.wrapper}>
  //             <div className={styles.loading}>
  //               <Loader className={styles.loader}/>
  //             </div>
  //           </div>
  //         : isLoading == false && stores.length == 0 ?
  //           <>
  //             <div className={styles.wrapper}>
  //               <EmptyState route={`/create-store/${appid}`} type="Store" />
  //             </div>
  //           </>
  //         : isLoading == false && stores.length > 0 ?
  //         <>
  //           <div className={styles.wrapper}>
  //             <div className={styles.seprator}></div>
  //                 <div className={styles.options}>
  //                   <div className={styles.filters}>
  //                     <div className={styles.buttons}>
  //                       <Icon name='filter' size='20' className={styles.icon} />
  //                       <span className={styles.buttonText}>Filter</span>
  //                     </div>
  //                     <div className={styles.buttons}>
  //                       <ArchiveSvg className={styles.icon} />
  //                       <span className={styles.buttonText}>Show Archived</span>
  //                     </div>
  //                   </div>
  //                   <div className={styles.searchForm}>
  //                     <Form className={styles.form} placeholder="Search Stores" type="text" name="search" icon="search" />
  //                   </div>
  //                 </div>
  //               {activeIndex === 0 && 
  //                 <>
  //                   <StoreTableLayout items={stores} title="Last edited" />
  //                 </> 
  //               }
  //           </div>
  //           <div className={styles.pagination}>
  //             <Stack spacing={2}>
  //               <Pagination renderItem={(item) => (
  //                 <PaginationItem
  //                   {...item}
  //                   sx={{
  //                     '&.Mui-selected': {
  //                       color: 'rgb(255, 255, 255)',
  //                       fontWeight : 'bold',
  //                       border : '1px solid rgb(42, 133, 255)',
  //                       background : 'rgba(42, 133, 255)'
  //                     },
  //                     '&.MuiPaginationItem-page.Mui-selected:hover' : {
  //                       color: 'rgb(255, 255, 255)',
  //                       fontWeight : 'bold',
  //                       border : '1px solid rgb(42, 133, 255)',
  //                       background : 'rgba(42, 133, 255)'
  //                     }
  //                   }}
  //                 />
  //               )} siblingCount={1} count={Math.ceil(totalStore/limit)} color="primary" onChange={handlePageChange} />
  //             </Stack>    
  //           </div> 
  //         </>
  //         : ''}
  //       </div> */}
  //     </Card>
  //     <div className="divider"></div>
  //     {activeIndex === 1 && (
  //       <>
  //         <div className={styles.list}>
  //           {stores.map((x, index) => (
  //               <StoreCardLayout className={styles.product} item={x} key={index} released />
  //           ))}
  //         </div>
  //       </>
  //     )}
  //       <div className={styles.msg}>
  //         <p className={styles.alert}>Learn more about stores <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/economy/stores" target="_blank">Click Here</a></p>
  //       </div>
  //   </>
  // )
}

export default GetStores