import React, {useState, useEffect, useContext} from 'react'
import cn from 'classnames'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import styles from './getdocuments.module.sass'
import Card from '../../../../components/Card'
import addIcon from '../../../../media/images/icons/light.png'
import Form from '../../../../components/Form'
import { ToastContainer } from 'react-toastify';
import Loader from '../../../../components/Loaders/LoaderProgress'
import DocumentTable from '../../../../components/AppSettingComponent/DocumentComponent/Table'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import { getAllDocumentsAction } from '../../../../redux/action/AppSettings/Document/documentAction'
import { Skeleton } from '@mui/material';
import EmptyStates from '../../../../components/EmptyStates'
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton';
import EmptyStateSkeleton from '../../../../components/Skeleton/EmptyStateSkeleton'
import Control from './Filters/Control'

const GetDocuments = ({className}) => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {appid} = useParams()
  // Filters
  const filters = {}
  // Search
  const [search, setSearch] = useState(null)
  // Sort
  const sortList = [{id: 1, name: 'Document Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
  const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
  const [selectedSort, setSelectedSort] = useState('updated_at')
  const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
  const [sortIndex, setSortIndex] = useState(0)
  // Pagination
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(8)

  const getDocumets = async(search) => {
    let document = {
      projectId : appid,
      // Search
      ...(search && search !== null && search !== '' && {search : search}),
      //Sort
      sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
      sortField : selectedSort,
      // Pagination
      offset: ((page-1)*limit),
      limit: limit
    }
    if(!search){
      await dispatch(getAllDocumentsAction(document, configureSkeleton))
    }else{
      await dispatch(getAllDocumentsAction(document, configureSkeleton))
    }
  }

  useEffect(() => {
    if(selectedSort !== null){
      getDocumets(search)
    }
  }, [page, selectedSort])

  let documents = useSelector((state) => {
    return state.document.documents
  })

  let isLoading = useSelector((state) => {
    return state.document.isLoading
  })

  let totalDocument = useSelector((state)=>{
    return state.document.totalCount
  })

  let isFiltered = useSelector((state)=>{
    return state.document.isFiltered
  })

  const [showSkeleton, setShowSkeleton] = useState(true)

  const configureSkeleton = () => {
    setShowSkeleton(true)
    setTimeout(() => {
      setShowSkeleton(false)
    }, 1000)
  }

  const onSearch = (search) => {
    if(search == ''){
      setSearch(null)
      getDocumets(null)
    }else{
      setSearch(search)
      getDocumets(search)
    }
  }

  const handlePageChange = (event, value)  => {
    setPage(value)
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }

  console.log(isLoading)

  // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

  return(
    <>
      <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={110} height={32}/> : 'Documents'} classTitle={cn("title-purple", styles.title)}
        head={
          (
            <>
              <div className={styles.add}>
                {showSkeleton && (documents?.length > 0 || (documents?.length === 0 && isFiltered)) > 0 ? 
                <>
                  <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={75} height={40}/>
                </>
                : !showSkeleton && (documents?.length > 0 || (documents?.length === 0 && isFiltered)) ?
                  <div className={styles.add}>
                    <div className={styles.createButton}>
                      <Link to={`/create-document/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                    </div>
                  </div>
                : ''}
              </div>
            </>
          )
        }
      >

        <div className={styles.wrapper}>
          <div className={styles.seprator}></div>
          <div className={styles.options}>
            {showSkeleton && (documents?.length > 0 || (documents?.length === 0 && isFiltered)) &&
              <>
                <div className={styles.filters}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={105} height={34}/>
                </div>

                <div className={styles.searchForm}>
                  <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40} />
                </div>
              </>
            }
            {!showSkeleton &&
              (documents?.length > 0 || (documents?.length === 0 && isFiltered) ?
                <>
                  <div className={styles.filters}>
                    <Control setPage={setPage} action={getDocumets} filters={filters} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue} />
                  </div>

                  <div className={styles.searchForm}>
                    <Form className={styles.form} placeholder="Search Documents" type="text" name="search" icon="search" value={search} onChange={(e) => {onSearch(e.target.value)}}/>
                  </div>
                </>
              : '')
            }
          </div>
          {showSkeleton && documents?.length === 0 &&
            <EmptyStateSkeleton />
          }
          {!showSkeleton && !isFiltered && documents?.length === 0 &&
            <EmptyStates route={`/create-document/${appid}`} type="Documents" />
          }
          {showSkeleton && documents?.length > 0 && 
            <SkeletonTable rows={documents.length} column={3} controls={1} type={['recatangle', 'recatangle', 'circular']} colWidth={[44, 100, 40]} colHeight={[44, 25, 40]} />
          }
          {!showSkeleton && !isFiltered && documents?.length > 0 &&
            <DocumentTable items={documents} title="Last edited" />
          }
          {!showSkeleton && isFiltered &&
            (documents?.length > 0 ?
              <DocumentTable items={documents} title="Last edited" />
                :
              <div className={styles.message}>No Result Found</div>
            )
          }
        </div>
          {documents?.length > 0 &&
            <div className={styles.pagination}>
              <Stack spacing={2}>
                <Pagination page={page} renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      '&.Mui-selected': {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      },
                      '&.MuiPaginationItem-page.Mui-selected:hover' : {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      }
                    }}
                  />
                )} siblingCount={1} count={Math.ceil(totalDocument/limit)} color="primary" onChange={handlePageChange} />
            </Stack>
          </div>
        }
      </Card>
      <div className="divider"></div>
      <div className={styles.msg}>
        <p className={styles.alert}>Learn more about Document <a className={styles.infoLink} href="#" target="_blank">Click Here</a></p>
      </div>
    </>
  )

}

export default GetDocuments