import { GET_PROGRESSION_SYSTEM, ADD_PROGRESSION_SYSTEM, EDIT_PROGRESSION_SYSTEM, FILTER_PROGRESSION_SYSTEM_SUCCESS, GET_PROGRESSION_SYSTEM_SUCCESS, FILTER_PROGRESSION_SYSTEM_ERROR, GET_PROGRESSION_SYSTEM_ERROR } from "../../../../actionTypes/actionTypes";
import progressionSystemServices from '../../../../../services/buildServices/progressionServices/progressionSystemServices/progressionSystemServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../../components/Toast";

export const getAllProgressionSystemAction = (body, showSkeleton) => {
    return async(dispatch) => {
        dispatch(getAllProgressionSystem(null, null, GET_PROGRESSION_SYSTEM))
        await progressionSystemServices.getAllProgressionSystem(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllProgressionSystem(res.data.data.levelDetails, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_PROGRESSION_SYSTEM_SUCCESS : GET_PROGRESSION_SYSTEM_SUCCESS)))
        }).catch(err=>{
            dispatch(getAllProgressionSystem(err.response, null, (body?.showArchived ? FILTER_PROGRESSION_SYSTEM_ERROR : GET_PROGRESSION_SYSTEM_ERROR)))
        })
    }
}

export const filterPSAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await progressionSystemServices.getAllProgressionSystem(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterPS(res.data.data.levelDetails, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_PROGRESSION_SYSTEM_SUCCESS))
        }).catch(err=>{
            dispatch(filterPS(err.response, null, FILTER_PROGRESSION_SYSTEM_ERROR))
        })
    }
}

export const createProgressionSystemAction = (body, navigate, appid, setDisable) => {
    return async(dispatch) => {
        setDisable(true)
        await progressionSystemServices.createProgressionSystem(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                localStorage.removeItem('Level')
                setDisable(false)
                navigate(`/progression-system/${appid}`)  
            }, 2000)
        }).catch(error => {
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
        })
    }
}

export const editProgressionSystemAction = (body, navigate, appid, setDisable) => {
    return async(dispatch) => {
        setDisable(true)
        await progressionSystemServices.editProgressionSystem(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                localStorage.removeItem('Level')
                setDisable(false)
                navigate(`/progression-system/${appid}`)  
            }, 2000)
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
        })
    }
}

export const archivePSAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        try {
            const res = await progressionSystemServices.archivePS(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                let data = {projectId : appid, showArchived: status, offset: 0, limit: 8}
                dispatch(getAllProgressionSystemAction(data))
                if(onClose){
                    onClose()
                }
            }
        }catch(error) {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
            console.log(error)
            throw error; 
        }
    }
}

export const getAllProgressionSystem = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const createProgressionSystem = (data) => {
    return {
        type : ADD_PROGRESSION_SYSTEM,
        payload : data
    }
}

export const editProgressionSystem = (data) => {
    return {
        type : EDIT_PROGRESSION_SYSTEM,
        payload : data
    }
}

export const filterPS = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}