import { GET_MATCHES, GET_MATCHES_SUCCESS, GET_MATCHES_ERROR, ADD_MATCHES, ADD_MATCHES_SUCCESS, ADD_MATCHES_ERROR, EDIT_MATCHES, EDIT_MATCHES_SUCCESS, EDIT_MATCHES_ERROR, GET_MATCH_DETAIL, GET_MATCH_DETAIL_SUCCESS, GET_MATCH_DETAIL_ERROR, FILTER_MATCHES_SUCCESS, FILTER_MATCHES_ERROR, GET_LEADERBOARD_MATCHES_SUCCESS, GET_LEADERBOARD_MATCHES_ERROR } from "../../../actionTypes/actionTypes";
import matchServices from "../../../../services/buildServices/matchServices/matchServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";
import {createLeaderboardAction, editLeaderboardAction} from '../../engage/Leaderboard/leaderboardAction'

export const getAllMatchesAction = (body, setIsCalled, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getAllMatches(null, null, GET_MATCHES))
        await matchServices.getAllMatches(body)
        .then(res => {
            if(setIsCalled){
                setIsCalled(true)
            }
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllMatches(res.data.data.matches, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_MATCHES_SUCCESS : GET_MATCHES_SUCCESS)))
        }).catch(err=>{
            if(setIsCalled){
                setIsCalled(false)
            }
            dispatch(getAllMatches(err.response, null, (body?.showArchived ? FILTER_MATCHES_ERROR : GET_MATCHES_ERROR)))
        })
    }
}

export const filterMatchesAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await matchServices.getAllMatches(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterMatches(res.data.data.matches, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_MATCHES_SUCCESS))
        }).catch(err=>{
            dispatch(filterMatches(err.response, null, FILTER_MATCHES_ERROR))
        })
    }
}

export const getAllLeaderboardMatchAction = (body) => {
    return async (dispatch) => {
        await matchServices.getAllLeaderboardMatches(body)
        .then(res => {
            dispatch(getAllLeaderboardMatches(res.data.data.matches, null, GET_LEADERBOARD_MATCHES_SUCCESS))
        }).catch(err=>{
            dispatch(getAllLeaderboardMatches(err, GET_LEADERBOARD_MATCHES_ERROR))
        })
    }
}

export const getMatcheDetailAction = (body) => {
    return async (dispatch) => {
        dispatch(getMatchDetail([], GET_MATCH_DETAIL))
        await matchServices.getAllMatches(body)
        .then(res => {
            dispatch(getMatchDetail(res.data.data.matches, GET_MATCH_DETAIL_SUCCESS))
        }).catch(err=>{
            dispatch(getMatchDetail(err, GET_MATCH_DETAIL_ERROR))
        })
    }
}

export const createMatchAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await matchServices.addMatch(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/matches/${appid}`)
            }, 1000)
            dispatch(addMatch(res.data.data, ADD_MATCHES_SUCCESS))
        }).catch(error=>{
            dispatch(addMatch(error, ADD_MATCHES_ERROR))
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editMatchAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await matchServices.editMatch(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/matches/${appid}`)
            }, 1000)
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const archiveMatchAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        await matchServices.archiveMatch(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let data = {projectId: appid, showArchived: status, offset: 0, limit: 8}
            dispatch(getAllMatchesAction(data))
            onClose()
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getAllMatches = (data, count, type) => {
    return{
        type : type,
        payload : data,
        totalCount : count,
    }
}

export const getAllLeaderboardMatches = (data, count , type) => {
    return{
        type : type,
        payload : data,
        totalCount : count,
    }
}

export const getMatchDetail = (data, type) => {
    return{
        type : type,
        payload : data,
    }
}

export const addMatch = (data, type) => {
    return{
        type : type,
        payload : data,
    }
}

export const editMatch = (data, type) => {
    return{
        type : type,
        payload : data,
    }
}

export const filterMatches = (data, count, type) => {
    return{
        type : type,
        payload : data,
        totalCount: count
    }
}