import {GET_STEP_SERIES, GET_STEP_SERIES_BY_ID, CREATE_STEP_SERIES, EDIT_STEP_SERIES, FILTER_STEP_SERIES_SUCCESS, GET_STEP_SERIES_SUCCESS, GET_STEP_SERIES_ERROR, FILTER_STEP_SERIES_ERROR} from "../../../../actionTypes/actionTypes";
import StepSeriesServices from '../../../../../services/engageServices/Achievement/StepSeries/StepSeriesServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../../components/Toast";

export const getAllStepSeriesAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getAllStepSeries(null, null, GET_STEP_SERIES))
        await StepSeriesServices.getAllStepSeries(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllStepSeries(res.data.data.taskGroups, res.data.data.totalCount, GET_STEP_SERIES_SUCCESS))
        }).catch(err=>{
            dispatch(getAllStepSeries(err.response, null, GET_STEP_SERIES_ERROR))
        })
    }
}

export const filterTaskGroupAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await StepSeriesServices.getAllStepSeries(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterTaskGroup(res.data.data.taskGroups, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), FILTER_STEP_SERIES_SUCCESS))
        }).catch(err=>{
            dispatch(filterTaskGroup(err.response, null, FILTER_STEP_SERIES_ERROR))
        })
    }
}

export const getStepSeriesByIdAction = (body) => {
    return async (dispatch) => {
        await StepSeriesServices.getStepSeriesById(body)
        .then(res=>{
            dispatch(getStepSeriesById(res.data.data.taskGroups))
        }).catch(err=>{
            console.log(err)
        })
    }
}

export const createStepSeriesAction = (body, navigate, appid, to, setDisable, visible, setVisible, setId) => {
    return async (dispatch) => {
        setDisable(true)
        await StepSeriesServices.createStepSeries(body)
        .then(res=>{
            if(setVisible){
                setId(res.data.data.id)
                sessionStorage.removeItem('step-series')
                sessionStorage.removeItem('contents')
                sessionStorage.removeItem('task-rewards')
                setVisible(true)
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            }else{
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                setTimeout(()=>{
                    sessionStorage.removeItem('step-series')
                    sessionStorage.removeItem('contents')
                    sessionStorage.removeItem('task-rewards')
                    setDisable(false)
                    if(to == 'step-series'){
                        navigate(`/step-series/${appid}`)
                    }else{
                        navigate(`/mission/${appid}`)
                    }
                }, 2000)
            }  
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editStepSeriesAction = (body, navigate, appid, to, setDisable, visible, setVisible) => {
    return async (dispatch) => {
        setDisable(true)
        await StepSeriesServices.editStepSeries(body)
        .then(res=>{
            if(setVisible){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                sessionStorage.removeItem('step-series')
                sessionStorage.removeItem('contents')
                sessionStorage.removeItem('task-rewards')
                setVisible(true)
            }else{
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                setTimeout(()=>{
                    setDisable(false)
                    sessionStorage.removeItem('step-series')
                    sessionStorage.removeItem('contents')
                    sessionStorage.removeItem('task-rewards')
                    if(to == 'step-series'){
                        navigate(`/step-series/${appid}`)
                    }else{
                        navigate(`/mission/${appid}`)
                    }
                }, 2000)
            }
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const archiveTaskGroupAction = (body, appid, onClose) => {
    return async (dispatch) => {
        await StepSeriesServices.archiveTaskGroup(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let data = {projectId: appid, ids: [], typeId: body.typeId, offset: 0, limit: 8}
            dispatch(getAllStepSeriesAction(data))
            onClose()
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const deleteTaskGroupAction = (body, appid, onClose) => {
    return async (dispatch) => {
        await StepSeriesServices.deleteTaskGroup(body)
        .then(res => {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let data = {projectId: appid, ids: [], typeId: body.typeId, offset: 0, limit: 8}
            dispatch(getAllStepSeriesAction(data))
            onClose()
        }).catch(error => {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getAllStepSeries = (data, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const filterTaskGroup = (data, count, type) => {
    return{
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getStepSeriesById = (data) => {
    return {
        type : GET_STEP_SERIES_BY_ID,
        payload : data
    }
}


export const createStepSeries = (data) => {
    return {
        type : CREATE_STEP_SERIES,
        payload : data
    }
}

export const editStepSeries = (data) => {
    return {
        type : EDIT_STEP_SERIES,
        payload : data
    }
}