import React from 'react'
import cn from 'classnames'
import styles from './radio.module.sass'

const Radio = ({ className, disabled, id, content, name, value, onChange}) => {

  return (
    <div>
        <label className={cn(styles.radio, className, {[styles.disabled] : disabled})}>
          <input disabled={disabled} className={styles.input} type="radio" name={name} onChange={onChange} checked={value} value={id} />
          <span className={styles.inner}>
          <span className={styles.tick}></span>
          <span className={styles.text} dangerouslySetInnerHTML={{ __html: content }}></span>
      </span>
    </label>
    </div>
  )
}

export default Radio