import { GET_COMPETITION_TOURNAMENTS, GET_COMPETITION_INSTANT_BATTLE, CREATE_COMPETITION_TOURNAMENT, CREATE_COMPETITION_INSTANT_BATTLE, EDIT_COMPETITION_TOURNAMENT, EDIT_COMPETITION_INSTANT_BATTLE, GET_COMPETITION_TOURNAMENTS_SUCCESS, GET_COMPETITION_TOURNAMENTS_FAILURE, GET_COMPETITION_INSTANT_BATTLE_SUCCESS, GET_COMPETITION_INSTANT_BATTLE_FAILURE, CREATE_COMPETITION_TOURNAMENT_SUCCESS, CREATE_COMPETITION_TOURNAMENT_ERROR, CREATE_COMPETITION_INSTANT_BATTLE_SUCCESS, CREATE_COMPETITION_INSTANT_BATTLE_ERROR, GET_COMPETITION_TOURNAMENTS_DETAILS, GET_COMPETITION_TOURNAMENTS_DETAILS_SUCCESS, GET_COMPETITION_TOURNAMENTS_DETAILS_FAILURE, GET_COMPETITION_INSTANT_BATTLE_DETAILS, GET_COMPETITION_INSTANT_BATTLE_DETAILS_SUCCESS, GET_COMPETITION_INSTANT_BATTLE_DETAILS_FAILURE, FILTER_COMPETITION_SUCCESS, GET_COMPETITION_RESULT_SUCCESS, GET_COMPETITION_RESULT_ERROR, FILTER_COMPETITION_ERROR } from "../../../actionTypes/actionTypes";
import CompetitionServices from '../../../../services/engageServices/Competition/CompetitionServices'
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../components/Toast";

export const getCompetitionTournamentAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getCompetitionTournament(GET_COMPETITION_TOURNAMENTS, null, null))
        await CompetitionServices.getCompetition(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            let updatedTournaments = res.data.data.competitionDetails && res.data.data.competitionDetails.map(item => ({
                ...item,
                scheduleInstances: item.scheduleDetails && item.scheduleDetails[0] && item.scheduleDetails[0]?.scheduleInstances.sort((a, b) => {
                  const dateA = new Date(a.startDate);
                  const dateB = new Date(b.startDate);
                  return dateB - dateA;
                })
            }));
            dispatch(getCompetitionTournament(GET_COMPETITION_TOURNAMENTS_SUCCESS, updatedTournaments, res.data.data.totalCount))
        }).catch(err=>{
            dispatch(getCompetitionTournament(GET_COMPETITION_TOURNAMENTS_FAILURE, err.response, null))
        })
    }
}

export const getCompetitionInstantBattleAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getCompetitionInstantBattle(GET_COMPETITION_INSTANT_BATTLE, null, null))
        await CompetitionServices.getCompetition(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            let updatedIB = res.data.data.competitionDetails && res.data.data.competitionDetails.map(item => ({
                ...item,
                scheduleInstances: item.scheduleDetails && item.scheduleDetails[0] && item.scheduleDetails[0]?.scheduleInstances.sort((a, b) => {
                  const dateA = new Date(a.startDate);
                  const dateB = new Date(b.startDate);
                  return dateB - dateA;
                })
            }));
            dispatch(getCompetitionInstantBattle(GET_COMPETITION_INSTANT_BATTLE_SUCCESS, updatedIB, res.data.data.totalCount))
        }).catch(err=>{
            dispatch(getCompetitionInstantBattle(GET_COMPETITION_INSTANT_BATTLE_FAILURE, err.response, null))
        })
    }
}

export const filterCompetitionAction = (body, showSkeleton, isFiltered) => {
    return async (dispatch) => {
        await CompetitionServices.getCompetition(body)
        .then(res=>{
            if(showSkeleton){
                showSkeleton()
            }
            let updatedCompetition = res.data.data.competitionDetails && res.data.data.competitionDetails.map(item => ({
                ...item,
                scheduleInstances: item.scheduleDetails && item.scheduleDetails[0] && item.scheduleDetails[0]?.scheduleInstances.sort((a, b) => {
                  const dateA = new Date(a.startDate);
                  const dateB = new Date(b.startDate);
                  return dateB - dateA;
                })
            }))
            dispatch(filterCompetition(FILTER_COMPETITION_SUCCESS, updatedCompetition, ((body?.search && body?.search !== '') ? res.data.data.searchCount : (body?.search === undefined && isFiltered) ? res.data.data.filterCount : res.data.data.totalCount), body.competitionFormatTypeMasterId))
        }).catch(err=>{
            dispatch(filterCompetition(FILTER_COMPETITION_ERROR, err.response, null, null))
        })
    }
}

export const getCompetitionTournamentDetailAction = (body) => {
    return async (dispatch) => {
        dispatch(getCompetitionTournamentByID(GET_COMPETITION_TOURNAMENTS_DETAILS, null))
        await CompetitionServices.getCompetition(body)
        .then(res=>{
            dispatch(getCompetitionTournamentByID(GET_COMPETITION_TOURNAMENTS_DETAILS_SUCCESS, res.data.data.competitionDetails))
        }).catch(err=>{
            dispatch(getCompetitionTournamentByID(GET_COMPETITION_TOURNAMENTS_DETAILS_FAILURE, err.response))
        })
    }
}

export const getCompetitionInstantBattleDetailsAction = (body) => {
    return async (dispatch) => {
        dispatch(getCompetitionInstantBattleID(GET_COMPETITION_INSTANT_BATTLE_DETAILS, null))
        await CompetitionServices.getCompetition(body)
        .then(res=>{
            dispatch(getCompetitionInstantBattleID(GET_COMPETITION_INSTANT_BATTLE_DETAILS_SUCCESS, res.data.data.competitionDetails))
        }).catch(err=>{
            dispatch(getCompetitionInstantBattleID(GET_COMPETITION_INSTANT_BATTLE_DETAILS_FAILURE, err.response))
        })
    }
}

export const createCompetitionTournamentAction = (body, navigate, appid, setDisable, visible, setVisible, setId) => {
    return async (dispatch) => {
        dispatch(addCompetitionTournament(CREATE_COMPETITION_TOURNAMENT, null))
        setDisable(true)
        await CompetitionServices.addCompetition(body)
        .then(res=>{
            localStorage.removeItem('PDRanks')
            if(setVisible){
                setVisible(true)
                setId(res.data.data.id)
                dispatch(addCompetitionTournament(CREATE_COMPETITION_TOURNAMENT_SUCCESS, res.data.data))
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            }else{
                if(navigate){
                    toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                    dispatch(addCompetitionTournament(CREATE_COMPETITION_TOURNAMENT_SUCCESS, res.data.data))
                    setTimeout(()=>{
                        setDisable(false)
                        navigate(`/competition-tournament/${appid}`)
                    }, 2000)
                }
            }
        }).catch(error=>{
            dispatch(addCompetitionTournament(CREATE_COMPETITION_TOURNAMENT_ERROR, error.response))
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const createCompetitionInstantBattleAction = (body, navigate, appid, setDisable, visible, setVisible, setId, from) => {
    return async (dispatch) => {
        setDisable(true)
        dispatch(addCompetitionInstantBattle(CREATE_COMPETITION_INSTANT_BATTLE, null))
        await CompetitionServices.addCompetition(body)
        .then(res=>{
            localStorage.removeItem('PDRanks')
            if(setVisible){
                setVisible(true)
                setId(res.data.data.id)
                dispatch(addCompetitionInstantBattle(CREATE_COMPETITION_INSTANT_BATTLE_SUCCESS, res.data.data))
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            }else{
                if(navigate){
                    toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                    dispatch(addCompetitionInstantBattle(CREATE_COMPETITION_INSTANT_BATTLE_SUCCESS, res.data.data))
                    setTimeout(()=>{
                        setDisable(false)
                        if(from == 'Tournament'){
                            navigate(`/competition-tournament/${appid}`)
                        }else{
                            navigate(`/competition-instant-battle/${appid}`)
                        }
                    }, 2000)
                }
            }
        }).catch(error=>{
            dispatch(addCompetitionInstantBattle(CREATE_COMPETITION_INSTANT_BATTLE_ERROR, error.response))
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editCompetitionTournamentAction = (body, navigate, appid, setDisable, visible, setVisible, to) => {
    return async (dispatch) => {
        setDisable(true)
        await CompetitionServices.editCompetition(body)
        .then(res=>{
            if(setVisible){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                localStorage.removeItem('Ranks')
                setVisible(true)
            }else{
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                localStorage.removeItem('Ranks')
                if(navigate){
                    setTimeout(() => {
                        setDisable(false)
                        if(to === 'Tournament'){
                            navigate(`/competition-tournament/${appid}`)
                        }else{
                            navigate(`/competition-instant-battle/${appid}`)
                        }
                    }, 2000);
                }
            }
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const editCompetitionInstantBattleAction = (body, navigate, appid, setDisable,visible, setVisible) => {
    return async (dispatch) => {
        setDisable(true)
        await CompetitionServices.editCompetition(body)
        .then(res=>{
            if(setVisible){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                localStorage.removeItem('Ranks')
                setVisible(true)
            }else{
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                localStorage.removeItem('Rans')
                setTimeout(()=>{
                    setDisable(false)
                    navigate(`/competition-instant-battle/${appid}`)
                }, 2000)
            }
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const archiveCompetitionAction = (body, appid, onClose) => {
    return async (dispatch) => {
        await CompetitionServices.archiveCompetition(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let data = {projectId: appid, competitionFormatTypeMasterId: body.competitionFormatTypeMasterId, offset: 0, limit: 8}
            dispatch(getCompetitionTournamentAction(data))
            onClose()
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const deleteCompetitionAction = (body, appid, onClose) => {
    return async (dispatch) => {
        await CompetitionServices.deleteCompetition(body)
        .then(res=>{
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            let data = {projectId: appid, competitionFormatTypeMasterId: body.competitionFormatTypeMasterId, offset: 0, limit: 8}
            dispatch(getCompetitionTournamentAction(data))
            onClose()
        }).catch(error=>{
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const getCompetitionResultAction = (body) => {
    return async(dispatch) => {
        await CompetitionServices.getCompetitionResult(body)
        .then(res => {
            dispatch(getCompetitionResult(res.data.data.competitionData, res.data.data.competitionDates, res.data.data.totalCount, GET_COMPETITION_RESULT_SUCCESS))
        }).catch(err => {
            dispatch(getCompetitionResult(err, null, null, GET_COMPETITION_RESULT_ERROR))
        })
    }
}

export const getCompetitionResult = (data, dates, count, type) => {
    return {
        type : type,
        payload : data,
        totalCount : count,
        dates : dates,
    }
}

export const getCompetitionTournament = (type, data, count) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getCompetitionInstantBattle = (type, data, count) => {
    return {
        type : type,
        payload : data,
        totalCount : count
    }
}

export const getCompetitionTournamentByID = (type, data) => {
    return {
        type : type,
        payload : data
    }
}

export const getCompetitionInstantBattleID = (type, data) => {
    return {
        type : type,
        payload : data
    }
}

export const addCompetitionTournament = (type, data) => {
    return {
        type : type,
        payload : data
    }
}

export const addCompetitionInstantBattle = (type, data) => {
    return {
        type : type,
        payload : data
    }
}

export const editCompetitionTournament = (data) => {
    return {
        type : EDIT_COMPETITION_TOURNAMENT,
        payload : data
    }
}

export const editCompetitionInstantBattle = (data) => {
    return {
        type : EDIT_COMPETITION_INSTANT_BATTLE,
        payload : data
    }
}

export const filterCompetition = (type, data, count, competition) => {
    return{
        type : type,
        payload : data,
        totalCount: count,
        competition: competition
    }
}