import React, {useEffect, useState} from 'react'
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import MatchesTableLayout from '../../../../components/BuildComponents/MatchComponent/MatchTableComponent'
import { useDispatch, useSelector } from 'react-redux';
import Form from '../../../../components/Form';
import Icon from "../../../../components/Icon";
import Card from "../../../../components/Card";
import addIcon from '../../../../media/images/icons/light.png'
import styles from './getMatches.module.sass'
import EmptyStates from '../../../../components/EmptyStates';
import Loader from '../../../../components/Loaders/LoaderProgress';
import { getAllMatchesAction, filterMatchesAction } from '../../../../redux/action/builds/matches/matchesAction';
import {ReactComponent as Previous} from '../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../media/images/icons/archive.svg'
import { Skeleton } from '@mui/material';
import SkeletonTable from '../../../../components/Skeleton/TableSkeleton';
import SkeletonEmptyState from '../../../../components/Skeleton/EmptyStateSkeleton';
import GlobalStateContext from '../../../../components/Context/GlobalStates/GlobalStateContext';
import { useContext } from 'react';
import Control from './Filters/Control';
import { ToastContainer } from 'react-toastify';

const sorting = ["list", "grid"];

const GetMatch = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
    // Pagination
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(8)
    // Archive
    const [showArchive, setShowArchive] = useState(false)
    // Filters and Search
    const [gameId, setGameId] = useState([])
    const [matchFormat, setMatchFormat] = useState([])
    const [minPlayer, setMinPlayer] = useState([0,0])
    const [maxPlayer, setMaxPlayer] = useState([0,0])
    const [matchOutcome, setMatchOutcone] = useState([])
    const [leaderboard, setLeaderboard] = useState(null)
    const [leaderboardRanking, setLeaderboardRanking] = useState([])
    const filters = {gameId, setGameId, matchFormat, setMatchFormat, minPlayer, setMinPlayer, maxPlayer, setMaxPlayer, matchOutcome, setMatchOutcone, leaderboard, setLeaderboard, leaderboardRanking, setLeaderboardRanking}
    // Search
    const [search, setSearch] = useState(null)
    // Sort
    const sortList = [{id: 1, name: 'Match Name',value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
    const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
    const [selectedSort, setSelectedSort] = useState('updated_at')
    const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
    const [sortIndex, setSortIndex] = useState(0)
    // Skeleton
    const [showSkeleton, setShowSkeleton] = useState(true)
    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    useEffect(() => {
      getUserAccess()
    }, [])

    const getAllMatches = async(search) => {
      let matches = {
        projectId : appid,
        // Pagination
        offset: ((page-1)*limit),
        limit: limit,
        // Search
        ...(search && search !== null && search !== '' && {search : search}),
        // Archive
        showArchived: showArchive,
        // Filter
        gameIds: gameId,
        matchFormatTypeMasterIds: matchFormat,
        ...(minPlayer[1] > 0 && {minPlayerStart : minPlayer[0]}),
        ...(minPlayer[1] > 0 && {minPlayerEnd : minPlayer[1]}),
        ...(maxPlayer[1] > 0 && {maxPlayerStart : maxPlayer[0]}),
        ...(maxPlayer[1] > 0 && {maxPlayerEnd : maxPlayer[1]}),
        matchOutcomeTypeMasterIds : matchOutcome,
        ...(leaderboard != null && {hasLeaderboard : leaderboard}),
        leaderboardRankingMethods : leaderboardRanking,
        //Sort
        sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
        sortField : selectedSort,
      }
      let keysToCheck = ['gameId', 'matchFormat', 'matchOutcome', 'leaderboardRanking']
      let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
      let hasLeaderboard = await filters.hasOwnProperty('leaderboard') && filters.leaderboard === null
      if(!search && isFiltered && hasLeaderboard){
        await dispatch(getAllMatchesAction(matches, null, configureSkeleton))
      }else{
        await dispatch(filterMatchesAction(matches, configureSkeleton, (!isFiltered || !hasLeaderboard)))
      }
    }

    let matches = useSelector((state) => {
      return state.matches.matches
    })

    let isLoading = useSelector((state) => {
      return state.matches.isLoading
    })

    let isFiltered = useSelector((state) => {
      return state.matches.isFiltered
    })

    let totalMatches = useSelector((state)=>{
      return state.matches.totalCount
    })

    const handlePageChange = (event, value)  => {
      setPage(value)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    const configureSkeleton = () => {
      setShowSkeleton(true)
      setTimeout(() => {
        setShowSkeleton(false)
      }, 1000)
    }

    useEffect(() => {
      if(selectedSort !== null){
        getAllMatches(search)
      }
    }, [page, selectedSort, showArchive])

    const onSearch = (search) => {
      if(search == ''){
        setSearch(null)
        getAllMatches(null)
      }else{
        setSearch(search)
        getAllMatches(search)
      }
    }

      // Loader
  if(isLoading){
    return(
      <>
        <div className={styles.loader}>
          <Loader />
        </div>
      </>
    )
  }

    return(
      <>
        <ToastContainer />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={70} height={32}/>: 'Matches'} classTitle={cn("title-purple", styles.title)}
          head={
            // Can make this as seperate component
            (
              <>
                <div className={styles.add}>
                  {showSkeleton && (matches?.length > 0 || (matches?.length === 0 && isFiltered)) ?
                    <>
                      <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px', marginLeft: '10px'}} animation="wave" variant="recatangle" width={95} height={40}/>
                    </>
                  : !showSkeleton && (matches?.length > 0 || (matches?.length === 0 && isFiltered)) ?
                    <>
                    {!userAccess &&
                      <>
                        <div className={styles.createButton}>
                          <Link to={`/create-match/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                        </div>
                      </>
                    }
                    </>
                  : ''}
                </div>
              </>
            )
          }
        >
          <div className={styles.wrapper}>

            <div className={styles.seprator}></div>
            <div className={styles.options}>
              {showSkeleton && matches.length > 0 &&
                <>
                  <div className={styles.filters}>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={120} height={34}/>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={70} height={34}/>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={105} height={34}/>
                  </div>

                  <div className={styles.searchForm}>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40} />
                  </div>
                </>
              }
                    
              {!showSkeleton &&
                (matches?.length > 0 || (matches?.length === 0 && isFiltered) ?
                  <>
                    <div className={styles.filters}>
                      <div className={cn(styles.buttons, {[styles.active] : showArchive})} onClick={() => setShowArchive(!showArchive)}>
                        <ArchiveSvg className={styles.icon} />
                        <span className={styles.buttonText}>Show Archived</span>
                      </div>
                      <Control setPage={setPage} action={getAllMatches} filters={filters} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
                    </div>

                    <div className={styles.searchForm}>
                      <Form className={styles.form} placeholder="Search Matches" type="text" name="search" icon="search" value={search} onChange={(e) => {onSearch(e.target.value)}}/>
                    </div>
                  </>
                : '')
              }
            </div>

            {/* Match Table View */}
            {showSkeleton && matches?.length === 0 && 
              <SkeletonEmptyState />
            }
            {showSkeleton && matches?.length > 0 && 
              <SkeletonTable rows={matches.length} column={7} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'circular']} colWidth={[44, 100, 100, 100, 100, 100, 40]} colHeight={[44, 25, 25, 25, 25, 25, 40]} />
            }
            {!showSkeleton && !isFiltered && matches?.length === 0 &&
              <EmptyStates access={userAccess} route={`/create-match/${appid}`} type="Match" />
            }
            {!showSkeleton && !isFiltered && matches?.length > 0 &&
              <MatchesTableLayout access={userAccess} items={matches} title="Last edited" />
            }
            {!showSkeleton && isFiltered &&
              (matches?.length > 0 ?
                <MatchesTableLayout access={userAccess} items={matches} title="Last edited" />
              :
                <div className={styles.message}>No Result Found</div>
              )
            }
          </div>

          {!isLoading && matches.length > 0 &&
            <div className={styles.pagination}>
              <Stack spacing={2}>
                <Pagination page={page} renderItem={(item) => (
                  <PaginationItem
                    {...item}
                    sx={{
                      '&.Mui-selected': {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      },
                      '&.MuiPaginationItem-page.Mui-selected:hover' : {
                        color: 'rgb(255, 255, 255)',
                        fontWeight : 'bold',
                        border : '1px solid rgb(42, 133, 255)',
                        background : 'rgba(42, 133, 255)'
                      }
                    }}
                  />
                )} siblingCount={1} count={Math.ceil(totalMatches/limit)} color="primary" onChange={handlePageChange} />
              </Stack>
            </div>
          }

        </Card>
        <div className="divider"></div>
        <div className={styles.msg}>
          <p className={styles.alert}>Learn more about Matches <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/build/matches" target="_blank">Click Here</a></p>
        </div>
      </>
  )
}

export default GetMatch