import React, { useContext, useEffect, useState } from "react";
import {routes} from '../../../../../routes/routes'
import cn from "classnames";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import styles from "./task.module.sass";
import Form from '../../../../../components/Form';
import Icon from "../../../../../components/Icon";
import Card from "../../../../../components/Card";
import TaskTableLayout from "../../../../../components/EngageComponents/AchievementComponent/TaskTableComponent";
import addIcon from '../../../../../media/images/icons/light.png'
import { filterTaskAction, getAllTaskAction } from "../../../../../redux/action/engage/Achievement/Task/TaskAction";
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import { ReactComponent as EmptyCurrencyState } from "../../../../../media/images/icons/Currency_Empty_State.svg";
import Loader from "../../../../../components/Loaders/LoaderProgress";
import EmptyState from '../../../../../components/EmptyStates'
import {ReactComponent as Previous} from '../../../../../media/images/icons/previous.svg'
import {ReactComponent as Next} from '../../../../../media/images/icons/next.svg'
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import Stack from '@mui/material/Stack';
import {ReactComponent as ArchiveSvg} from '../../../../../media/images/icons/archive.svg'
import { Skeleton } from "@mui/material";
import SkeletonTable from "../../../../../components/Skeleton/TableSkeleton";
import SkeletonEmptyState from "../../../../../components/Skeleton/EmptyStateSkeleton";
import GlobalStateContext from "../../../../../components/Context/GlobalStates/GlobalStateContext";
import Calendar from "../../../LiveOps/Calendar";
import Control from "./Filters/Control";
import moment from "moment";

const sorting = ["list", "grid"];

const Task = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {appid} = useParams()
  
    const [activeIndex, setActiveIndex] = useState(0);
    const [page, setPage] = useState(1)
    const [limit, setLimit] = useState(8)
    // Filters
    const [recurrance, setRecurrance] = useState(null)
    const [recurranceInterval, setRecurranceInterval] = useState(null)
    const [recurranceIntervalId, setRecurranceIntervalId] = useState(null)
    const [frequency, setFrequency] = useState(null)
    const [defaultEvent, setDefaultEvent] = useState([])
    const [customEvent, setCustomEvent] = useState([])
    const [startDate, setStartDate] = useState('')
    const [endDate, setEndDate] = useState('')
    const [status, setStatus] = useState([])
    const [instanceStatus, setInstanceStatus] = useState([])
    const filters = {frequency, setFrequency, defaultEvent, setDefaultEvent, customEvent, setCustomEvent, startDate, setStartDate, endDate, setEndDate, status, setStatus, instanceStatus, setInstanceStatus, recurrance, setRecurrance, recurranceInterval, setRecurranceInterval, recurranceIntervalId, setRecurranceIntervalId}
    // Search
    const [search, setSearch] = useState(null)
    // Sort
    const sortList = [{id: 1, name: 'Task Name', value: 'name'}, {id: 2, name: 'Created At', value: 'created_at'}, {id: 3, name: 'Updated At', value: 'updated_at'}]
    const [sortValue, setSortValue] = useState({name: 'updated_at', value: 0})
    const [selectedSort, setSelectedSort] = useState('updated_at')
    const [sort, setSort] = useState([{id: 1, name: 'Ascending'}, {id : 2, name : 'Descending'}])
    const [sortIndex, setSortIndex] = useState(0)
    // View
    const [activeViewIndex, setActiveViewIndex] = React.useState(0);
    const modes = [{value:"list",icon:"list"}, {value:"calendar",icon:"calendar"}];

    const [userAccess, setUserAccess] = useState(false)
    const globalStates = useContext(GlobalStateContext)
  
    const getUserAccess = () => {
      if(globalStates && globalStates.user && globalStates.user.memberAccessConfig?.length > 0){
        let appPermission = globalStates.user.memberAccessConfig.filter((obj) => (obj.projectId === appid))
        if(appPermission?.length > 0){
          setUserAccess(appPermission[0].permissions.id === 1 ? true : false)
        }
      }
    }
    
    useEffect(() => {
      getUserAccess()
    }, [])
  
    const getAllTask = async(search) => {
      let getAllTask = {
        projectId : appid,
        ids : [],
        // Search
        ...(search && search !== null && search !== '' && {search : search}),
        // Sort
        sortOrder : (sortIndex == 0 ? 'asc' : 'desc'),
        sortField : selectedSort,
        //Filters
        ...((recurrance !== null && recurrance !== '') && {recurrenceFrequency: recurrance}),
        ...((recurranceIntervalId !== null && recurrance !== '') && {intervalUnitId: recurranceIntervalId}),
        ...(frequency !== null && {isRecurring  : frequency}),
        ...(defaultEvent?.length > 0 && {defaultEventIds : defaultEvent}),
        ...(customEvent?.length > 0 && {customEventIds : customEvent}),
        ...(startDate !== '' && {startDate : (moment(startDate).format('YYYY-MM-DD')).concat(' 00:00:00+00:00')}),
        ...(endDate !== '' && {endDate : (moment(endDate).format('YYYY-MM-DD')).concat(' 00:00:00+00:00')}),
        ...(status?.length > 0 && {status : status}),
        ...(instanceStatus?.length > 0 && {instanceStatus : instanceStatus}),
        // Pagination
        offset: ((page-1)*limit),
        limit: limit
      }
      let keysToCheck = ['defaultEvent', 'customEvent', 'status', 'instanceStatus']
      let isFiltered = await keysToCheck.every(key => filters[key]?.length === 0)
      let isRecurring = await (filters.hasOwnProperty('frequency') && filters.frequency === null) && (filters.hasOwnProperty('startDate') && filters.startDate === '') && (filters.hasOwnProperty('endDate') && filters.endDate === '') && (filters.hasOwnProperty('recurrance') && filters.recurrance === null) && (filters.hasOwnProperty('recurranceIntervalId') && filters.recurranceIntervalId === null) 
      if(!search && isFiltered && isRecurring){
        await dispatch(getAllTaskAction(getAllTask, configureSkeleton))
      }else{
        await dispatch(filterTaskAction(getAllTask, configureSkeleton, (!isFiltered || !isRecurring)))
      }
    }

    let Task = useSelector((state) => {
      return state.task.tasks
    })

    let totalTask = useSelector((state) => {
      return state.task.totalCount
    })

    let isLoading = useSelector((state) => {
      return state.task.isLoading
    })

    let isFiltered = useSelector((state) => {
      return state.task.isFiltered
    })

    useEffect(()=>{
      sessionStorage.removeItem('contents')
    }, [])

    const [showSkeleton, setShowSkeleton] = useState(true)

    const configureSkeleton = () => {
      setShowSkeleton(true)
      setTimeout(() => {
        setShowSkeleton(false)
      }, 1000)
    }

    const handlePageChange = (event, value)  => {
      setPage(value)
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }

    useEffect(() => {
      if(selectedSort !== null){
        getAllTask(search)
      }
    }, [page, selectedSort])

    const onSearch = (search) => {
      if(search == ''){
        setSearch(null)
        getAllTask()
      }else{
        setSearch(search)
        getAllTask(search)
      }
    }

    // Loader
    if(isLoading){
      return(
        <>
          <div className={styles.loader}>
            <Loader />
          </div>
        </>
      )
    }

    return(
      <>
        <ToastContainer />
        <Card layOut={`Get`} className={styles.card} classCardHead={styles.head} title={showSkeleton ? <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '4px'}} animation="wave" variant="recatangle" width={80} height={32}/> : 'Tasks'} classTitle={cn("title-purple", styles.title)}
        head={
          (
            <>
              {showSkeleton && Task.length > 0 ?
                <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={70} height={40}/>
              : !showSkeleton && Task.length > 0 &&
              <>
                <div className={styles.toggle}>
                  {modes && modes.map((x, index) => (
                    <button style={{height:"32px",width:"32px"}} className={cn(styles.link, { [styles.active]: index === activeViewIndex,})} onClick={() => setActiveViewIndex(index)} key={index}>
                      <Icon name={x.icon} size='20'/>
                    </button>
                  ))}
                </div>
              </>
              }
              <div className={styles.add}>
                {showSkeleton && (Task?.length > 0 || (Task?.length === 0 && isFiltered))?
                  <Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={98} height={40}/>
                : !showSkeleton && (Task?.length > 0 || (Task?.length === 0 && isFiltered)) ?
                  <>
                  {!userAccess &&
                    <div className={styles.createButton}>
                      <Link to={`/create-task/${appid}`} className={cn("button-small", styles.button)}><img className={styles.addIcon} src={addIcon} alt='gamehub_add_icon'width={20} height={20}/>Create</Link>
                    </div>
                  }
                  </>
                : ''}
              </div>
            </>
          )
        }>
        
        {activeViewIndex === 0 &&
          <div className={styles.wrapper}>
            <div className={styles.seprator}></div>
            <div className={styles.options}>
              {showSkeleton && Task.length > 0 &&
                <>
                  <div className={styles.filters}>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={70} height={34}/>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '8px'}} animation="wave" variant="recatangle" width={105} height={34}/>
                  </div>

                  <div className={styles.searchForm}>
                    <Skeleton Skeleton sx={{ bgcolor: 'grey.200', borderRadius : '12px'}} animation="wave" variant="recatangle" width={220} height={40} />
                  </div>
                </>
              }
                    
              {!showSkeleton &&
                (Task?.length > 0 || (Task?.length === 0 && isFiltered) ?
                  <>
                    <div className={styles.filters}>
                      <Control setPage={setPage} filters={filters} action={getAllTask} data={sortList} sortIndex={sortIndex} setSortIndex={setSortIndex} selected={selectedSort} setSelected={setSelectedSort} sort={sort} setSort={setSort} value={sortValue} setValue={setSortValue}/>
                    </div>
                    <div className={styles.searchForm}>
                      <Form className={styles.form} placeholder="Search Tasks" type="text" name="search" icon="search" value={search} onChange={(e) => {onSearch(e.target.value)}}/>
                    </div>
                  </>
                : '')
              } 
            </div>
            {/* Task Table View */}
            {showSkeleton && Task?.length === 0 && 
              <SkeletonEmptyState />
            }
            {showSkeleton && Task?.length > 0 && 
              <SkeletonTable rows={Task.length} column={7} controls={2} type={['recatangle', 'recatangle', 'recatangle', 'reactangle', 'reactangle', 'reactangle', 'circular']} colWidth={[44, 100, 100, 100, 100, 100, 40]} colHeight={[44, 25, 25, 25, 25, 25, 40]} />
            }
            {!showSkeleton && !isFiltered && Task?.length === 0 &&
              <EmptyState access={userAccess} route={`/create-task/${appid}`} type="Task" />
            }
            {!showSkeleton && !isFiltered && Task?.length > 0 &&
              <TaskTableLayout access={userAccess} items={Task} title="Last edited" />
            }
            {!showSkeleton && isFiltered &&
              (Task?.length > 0 ?
                <TaskTableLayout access={userAccess} items={Task} title="Last edited" />
              :
                <div className={styles.message}>No Result Found</div>
              )
            }

            {!isLoading && Task.length > 0 &&
              <div className={styles.pagination}>
                <Stack spacing={2}>
                  <Pagination page={page} renderItem={(item) => (
                    <PaginationItem
                      {...item}
                      sx={
                      {
                        '&.Mui-selected': {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        },
                        '&.MuiPaginationItem-page.Mui-selected:hover' : {
                          color: 'rgb(255, 255, 255)',
                          fontWeight : 'bold',
                          border : '1px solid rgb(42, 133, 255)',
                          background : 'rgba(42, 133, 255)'
                        }
                      }}
                    />
                  )} siblingCount={1} count={Math.ceil(totalTask/limit)} color="primary" onChange={handlePageChange} />
                </Stack>
              </div>
            }
          </div>
        }
        {activeViewIndex === 1 && <div><Calendar/></div>}
        </Card>
        <div className="divider"></div>
        <div className={styles.msg}>
          <p className={styles.alert}>Learn more about Task <a className={styles.infoLink} href="https://manual.specterapp.xyz/specter-user-manual/engage/achievements/tasks" target="_blank">Click Here</a></p>
        </div>
      </>
    )

};

export default Task;
