import { GET_PROGRESSION_MARKER, ADD_PROGRESSION_MARKER, EDIT_PROGRESSION_MARKER, FILTER_PROGRESSION_MARKER_SUCCESS, GET_PROGRESSION_MARKER_SUCCESS, GET_PROGRESSION_MARKER_ERROR, FILTER_PROGRESSION_MARKER_ERROR } from "../../../../actionTypes/actionTypes";
import progressionMarkerServices from "../../../../../services/buildServices/progressionServices/progressionMarkerServices/progressionMarkerServices";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Toast from "../../../../../components/Toast";

export const getAllProgressionMarkerAction = (body, showSkeleton) => {
    return async (dispatch) => {
        dispatch(getAllProgressionMarker(null, null, GET_PROGRESSION_MARKER))
        await progressionMarkerServices.getAllProgressionMarker(body)
        .then(res=> {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(getAllProgressionMarker(res.data.data.progressionMarkers, (body?.showArchived ? res.data.data.archiveCount : res.data.data.totalCount), (body?.showArchived ? FILTER_PROGRESSION_MARKER_SUCCESS : GET_PROGRESSION_MARKER_SUCCESS)))
        }).catch(err=>{
            dispatch(getAllProgressionMarker(err.response, null, (body?.showArchived ? FILTER_PROGRESSION_MARKER_ERROR : GET_PROGRESSION_MARKER_ERROR)))
        })
    }
}

export const filterPMAction = (body, showSkeleton) => {
    return async (dispatch) => {
        await progressionMarkerServices.getAllProgressionMarker(body)
        .then(res => {
            if(showSkeleton){
                showSkeleton()
            }
            dispatch(filterPM(res.data.data.progressionMarkers, ((body?.search && body?.search !== '') ? res.data.data.searchCount : res.data.data.totalCount), FILTER_PROGRESSION_MARKER_SUCCESS))
        }).catch(err=>{
            dispatch(filterPM(err.response, null, FILTER_PROGRESSION_MARKER_ERROR))
        })
    }
}

export const createProgressionMarkerAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await progressionMarkerServices.createProgressionMarker(body)
        .then(res=> {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/progression-marker/${appid}`)
            }, 2000)
        }).catch((error)=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                    toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
                })
        })
    }
}

export const editProgressionMarkerAction = (body, navigate, appid, setDisable) => {
    return async (dispatch) => {
        setDisable(true)
        await progressionMarkerServices.editProgressionMarker(body)
        .then(res=> {
            toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
            setTimeout(()=>{
                setDisable(false)
                navigate(`/progression-marker/${appid}`)
            }, 2000)
        }).catch(error=>{
            setDisable(false)
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
        })
    }
}

export const archivePMAction = (body, appid, onClose, status) => {
    return async(dispatch) => {
        try {
            const res = await progressionMarkerServices.archivePM(body)
            if(res){
                toast.success(<Toast type='Success' messages={res.data.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#DEF1E5'}})
                let data = {projectId: appid, showArchived: status, ids:  [], offset: 0, limit: 8}
                dispatch(getAllProgressionMarkerAction(data))
                if(onClose){
                    onClose()
                }
            }
        }catch(error) {
            error.response.data.errors && error.response.data.errors.map((err,i)=>{
                toast.error(<Toast type='Error' messages={err.message}/>, {position : 'top-right', icon: false, hideProgressBar : true, autoClose: 2000, style : {background : '#FAE8E7'}})
            })
            throw error; 
        }
    }
}

export const getAllProgressionMarker = (data, count, type) => {
    return {
        payload : data,
        totalCount : count,
        type : type
    }
}

export const createProgressionMarker = (data) => {
    return {
        type : ADD_PROGRESSION_MARKER,
        payload : data
    }
}

export const editProgressionMarker = (data) => {
    return {
        type : EDIT_PROGRESSION_MARKER,
        payload : data
    }
}

export const filterPM = (data, count, type) => {
    return {
        payload : data,
        totalCount : count,
        type : type
    }
}